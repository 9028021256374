<template>
  <v-container>
    <div>
      <p class="c4"><span class="c22"></span></p>
    </div>
    <p class="c15 c18"><span class="c2">Terms &amp; Conditions</span></p>
    <p class="c11"><span class="c2"></span></p>
    <ol class="c3 lst-kix_list_1-0 start" start="1">
      <li class="c10 li-bullet-0"><span class="c2">Introduction</span></li>
    </ol>
    <p class="c4 c16"><span class="c1"></span></p>
    <p class="c0">
      <span class="c5">The domain name www.gyanathon.com (the &ldquo;</span
      ><span class="c14">Site</span
      ><span class="c5"
        >&rdquo;), maintained and operated by Gyanathon Learning Solutions
        Private Limited (&ldquo;</span
      ><span class="c14">GLSPL</span
      ><span class="c5"
        >&rdquo;). The terms and conditions set out below (the &ldquo;</span
      ><span class="c14">Conditions</span
      ><span class="c5"
        >&rdquo;) apply to the use of the Site or any product or services in
        connection with the Site or any application, including the use of
        information services offered on the Site and are intended to govern the
        use of the Site between GLSPL and the user (&ldquo;</span
      ><span class="c14">User/You/Your</span><span class="c1">&rdquo;). </span>
    </p>
    <p class="c7"><span class="c1"></span></p>
    <p class="c0">
      <span class="c1"
        >In accessing, using, viewing, transmitting, caching or storing this
        Site or any of its services, functions, materials or contents, you shall
        be deemed to have agreed to each and all the Conditions and notices on
        this Site as amended from time to time. Further, you agree to be bound
        by these Conditions, and are therefore requested to carefully read this
        section before proceeding. If you do not accept these Conditions, you
        must refrain from using the Site. These Conditions must be read in
        conjunction with any other applicable terms and conditions governing the
        use of the Site.
      </span>
    </p>
    <p class="c0 c13"><span class="c1"></span></p>
    <p class="c0">
      <span class="c1"
        >GLSPL reserves the right to change these terms from time to time. By
        continuing to use the Site after changes are po sted you agree to be
        legally bound by those terms as updated and/or amended.</span
      >
    </p>
    <p class="c4 c16"><span class="c1"></span></p>
    <p class="c0">
      <span class="c1"
        >These Conditions constitute an electronic record in accordance with the
        provisions of the Information Technology Act, 2000 and the Information
        Technology (Intermediaries guidelines) Rules,&nbsp;2011 thereunder, as
        amended from time to time.</span
      >
    </p>
    <p class="c4 c16"><span class="c1"></span></p>
    <p class="c4 c16"><span class="c1"></span></p>
    <ol class="c3 lst-kix_list_1-0" start="2">
      <li class="c10 li-bullet-0"><span class="c2">Trade Marks</span></li>
    </ol>
    <p class="c4 c16"><span class="c8 c5"></span></p>
    <p class="c0">
      <span class="c5"
        >The Site contains GLSPL&rsquo;s trade and service marks and devices,
        including the word &ldquo;Gyanathon&rdquo;, and other valuable trade or
        service marks owned by GLSPL or licensed to GLSPL, to distinguish its
        services and products (collectively referred to as &ldquo;</span
      ><span class="c14">GLSPL Intellectual Property</span
      ><span class="c1"
        >&rdquo;). The GLSPL Intellectual Property is protected from copying and
        simulation under national and international laws and may not be
        reproduced, copied or used in any other manner whatsoever, on any
        material, whether tangible or intangible, without the express prior
        written consent of GLSPL. Without limitation, you must not use any GLSPL
        Intellectual Property or a variation thereof, either alone or in
        conjunction or as a combination with any other trade or service marks,
        logos and devices:</span
      >
    </p>
    <p class="c0 c13"><span class="c1"></span></p>
    <ul class="c3 lst-kix_list_2-0 start">
      <li class="c6 li-bullet-1">
        <span class="c1"
          >in or as the whole or part of your own trade marks;</span
        >
      </li>
      <li class="c6 li-bullet-2">
        <span class="c1"
          >in connection with activities, products or services which are not
          undertaken or provided by GLSPL;</span
        >
      </li>
      <li class="c6 li-bullet-1">
        <span class="c1"
          >in a manner which may be confusing, misleading or deceptive; or</span
        >
      </li>
      <li class="c6 li-bullet-1">
        <span class="c1"
          >in a manner that disparages GLSPL or its information, products or
          services (including this Site).</span
        >
      </li>
    </ul>
    <p class="c7 c15"><span class="c1"></span></p>
    <p class="c0">
      <span class="c5"
        >Trade and service marks and devices not forming a part of the GLSPL
        Intellectual Property, but included on the Site (the &ldquo;</span
      ><span class="c14">Third Party Marks</span
      ><span class="c1"
        >&rdquo;) shall belong to the respective owners of such Third Party
        Marks. You acknowledge that the use and display of such Third Party
        Marks are subject to the rights of the owners of the Third Party
        Marks.</span
      >
    </p>
    <p class="c4 c12"><span class="c1"></span></p>
    <ol class="c3 lst-kix_list_1-0" start="3">
      <li class="c10 li-bullet-0"><span class="c2">Copyright</span></li>
    </ol>
    <p class="c4"><span class="c1"></span></p>
    <p class="c0">
      <span class="c5"
        >The information, text, graphics, images, sounds, links and all other
        information and software published or otherwise contained on the Site
        (&ldquo;</span
      ><span class="c14">Information</span
      ><span class="c1"
        >&rdquo;) are either owned exclusively by GLSPL or licensed to GLSPL and
        may not be copied, distributed, displayed, reproduced or transmitted in
        any form or by any means, whether electronic, mechanical, photocopying,
        recording or otherwise, without the prior written approval of GLSPL.
      </span>
    </p>
    <p class="c4"><span class="c1"></span></p>
    <p class="c0">
      <span class="c1"
        >Without limitation, you may not, without such approval from GLSPL,
        create derivative works from any part of the Site or commercialise any
        Information, products or services obtained from any part of the Site.
        Information procured from a third party may be the subject of copyright
        owned by that third party. Unauthorised use of the Site and/or the
        materials contained on the Site may violate applicable copyright,
        trademark or other intellectual property laws or other laws. You must
        retain all copyright and trademark notices, including any other
        proprietary notices, contained in the materials. The use of such
        materials on any other website or in any environment of networked
        computers is strictly prohibited.</span
      >
    </p>
    <p class="c4"><span class="c8 c5"></span></p>
    <ol class="c3 lst-kix_list_1-0" start="4">
      <li class="c10 li-bullet-0"><span class="c2">Site Use</span></li>
    </ol>
    <p class="c4"><span class="c8 c5"></span></p>
    <p class="c0">
      <span class="c1"
        >The Site is for Your personal non-commercial use and the Information
        may be downloaded by you solely for that purpose. You are responsible
        for maintaining the secrecy of your passwords, login and account
        information. You will be financially accountable for any use of the Site
        by You and anyone using your password and login information. This right
        to use the Information is a limited, non-excusive, non-transferrable,
        non-assignable, time-bound, non-sub-licensable, revocable license only,
        and is subject to the following restrictions:</span
      >
    </p>
    <p class="c7 c15"><span class="c1"></span></p>
    <ul class="c3 lst-kix_list_2-0">
      <li class="c6 li-bullet-1">
        <span class="c1"
          >the Information may not be used for any commercial purpose or public
          display, performance, sale or rental;</span
        >
      </li>
      <li class="c6 li-bullet-1">
        <span class="c1"
          >no copyright or other proprietary notices may be removed;</span
        >
      </li>
      <li class="c6 li-bullet-2">
        <span class="c1"
          >the Information may not be transferred to another person;</span
        >
      </li>
      <li class="c6 li-bullet-1">
        <span class="c1"
          >software may not be interfered with in any manner;</span
        >
      </li>
      <li class="c6 li-bullet-2">
        <span class="c1"
          >every person downloading, reproducing or otherwise using the
          Information must prevent any unauthorised copying of the Information;
        </span>
      </li>
      <li class="c6 li-bullet-1">
        <span class="c1"
          >You shall only use the Information for lawful purposes and in
          accordance with these Conditions;
        </span>
      </li>
      <li class="c6 li-bullet-1">
        <span class="c1"
          >You shall not frame or utilise framing techniques to enclose any
          GLSPL Intellectual Property;
        </span>
      </li>
      <li class="c6 li-bullet-1">
        <span class="c1"
          >You shall not collect and use any educational material, training
          videos, descriptions or other Information belonging to GLSPL for the
          benefit of other merchants, including direct and indirect competitors
          of GLSPL; and</span
        >
      </li>
      <li class="c6 li-bullet-1">
        <span class="c1"
          >You shall not use any meta tags or any other hidden text utilising
          the Site&rsquo;s, GLSPL&rsquo;s name or trademarks without the express
          written consent of GLSPL.</span
        >
      </li>
      <li class="c6 li-bullet-2">
        <span class="c1 c17"
          >You shall not make available any content that is misleading,
          unlawful, harmful, threatening, abusive, tortious, defamatory,
          libelous, vulgar, obscene, child-pornographic, lewd, lascivious,
          profane, invasive of another&rsquo;s privacy, hateful, or racially,
          ethnically, communally or otherwise objectionable.</span
        >
      </li>
      <li class="c6 li-bullet-1">
        <span class="c1 c17"
          >You shall not indulge in stalking, intimidating and/or harassing
          another and/or inciting other to commit violence.</span
        >
      </li>
      <li class="c6 li-bullet-1">
        <span class="c1 c17"
          >You shall not make, transmit or store electronic copies of materials
          protected by copyright without the permission of the owner, committing
          any act that amounts to the infringement of intellectual property or
          making available any material that infringes any intellectual property
          rights or other proprietary rights of anyone else.</span
        >
      </li>
      <li class="c6 li-bullet-1">
        <span class="c1 c17">You shall not Impersonate</span
        ><span class="c1"
          >&nbsp;any person or entity, or falsely state or otherwise
          misrepresent Your affiliation with a person or entity;</span
        >
      </li>
      <li class="c6 li-bullet-1">
        <span class="c1"
          >Post, transmit or make available any material that contains viruses,
          trojan horses, worms, spyware, time </span
        ><span class="c1 c17">bombs</span
        ><span class="c1"
          >, cancelbots, or other computer programming routines, code, files or
          such other programs that may harm the Application/website/services,
          interests or rights of other users or limit the functionality of any
          computer software, hardware or telecommunications, or that may harvest
          or collect any data or personal information about other Users without
          their consent; or
        </span>
      </li>
      <li class="c6 li-bullet-1">
        <span class="c1"
          >Alter or modify any parts of the services being offered by
          GLSPL.</span
        >
      </li>
    </ul>
    <p class="c4"><span class="c1"></span></p>
    <p class="c0">
      <span class="c1"
        >If You are found to be in violation of these Conditions, including
        specifically in violation of any of the restrictions set out above,
        GLSPL shall have the right to permanently ban your account from further
        use or access to the Site or the services provided therein.</span
      >
    </p>
    <p class="c0 c13"><span class="c1"></span></p>
    <p class="c0">
      <span class="c1"
        >You shall pay all costs and charges, including phone charges and
        telecommunications equipment costs, that you incur in order to access or
        use the Site, services and Information. You must adhere to all
        limitations on dissemination, usage and reproduction of any Information
        that you download from the Site that are subject to such limitations.
        You agree to use the Site only to access, utilise, and receive
        Information in an appropriate manner and purchase services being sold by
        GLSPL on the Site as being accessed or used by You.</span
      >
    </p>
    <p class="c0 c13"><span class="c1"></span></p>
    <p class="c0">
      <span class="c5"
        >If You are required to establish an account / provide information to
        access the information on the Site or to purchase any services with the
        use of the Site, you must complete the designated registration process
        to obtain Information or purchase any products as the case may be. All
        required registration information that You provide must be current,
        complete and accurate and kept up to date on a prompt, timely basis. If
        required, you must also choose a user name and password for such
        account. It is Your sole responsibility to keep your account number,
        user name, password and other sensitive information confidential.
        Information and services provided by third parties (collectively, the
        &ldquo;</span
      ><span class="c14">Third-Party Services</span
      ><span class="c1"
        >&rdquo;) are governed by separate agreements accompanying such
        Information and services. GLSPL offers no guarantees and assumes no
        responsibility or liability of any type with respect to the Third-Party
        Services, including any liability resulting from incompatibility between
        a Third-Party Service, GLSPL&rsquo;s Information, GLSPL provided
        services or another Third-Party Service. You agree that you will not
        hold GLSPL responsible or liable with respect to the Third-Party
        Services.
      </span>
    </p>
    <p class="c0 c13"><span class="c1"></span></p>
    <p class="c0">
      <span class="c1"
        >You agree and acknowledge that the services offered by GLSPL through
        the Site is restricted to certain geographical areas. GLSPL shall not be
        responsible for any loss or damage incurred by you, due to the service
        not being available in the geographical area that you are situated while
        accessing the services provided on the Site.
      </span>
    </p>
    <p class="c0 c13"><span class="c1"></span></p>
    <p class="c0">
      <span class="c5"
        >Persons who are &quot;incompetent to contract&quot; within the meaning
        of the Indian Contract Act, 1872 including un-discharged insolvents etc.
        are not eligible to use the Site. If you are a minor i.e. under the age
        of eighteen (18) years, you may use the Site only under the supervision
        of a parent or legal guardian who agrees to be bound by these
        Conditions. </span
      ><span class="c24">&nbsp;</span
      ><span class="c1"
        >In the event a minor utilizes the Site, it is assumed that he/she has
        obtained the consent of the legal guardian or parents and such use is
        made available by the legal guardian or parents. GLSPL will not be
        responsible for any consequence that arises as a result of misuse of any
        kind of services that may occur by virtue of any person including a
        minor registering for the services provided. By using the services, You
        warrant that all the data provided by You is accurate and complete and
        that student using the Site has obtained the consent of parent/legal
        guardian (in case of minors). GLSPL reserves the right to terminate Your
        subscription and / or refuse to provide You with access to the services
        if it is discovered that You are under the age of eighteen (18) years
        and the consent to use the products or services is not made by Your
        parent/legal guardian or any information provided by You is inaccurate.
        You acknowledge that GLSPL does not have the responsibility to ensure
        that You conform to the aforesaid eligibility criteria. It shall be Your
        sole responsibility to ensure that You meet the required qualification.
        Any persons under the age of eighteen (18) should seek the consent of
        their parents/legal guardians before providing any Information about
        themselves or their parents and other family members on the Site.</span
      >
    </p>
    <p class="c0 c13"><span class="c1"></span></p>
    <p class="c0">
      <span class="c1"
        >By agreeing to the Conditions and creating an account on the Site, You
        agree to receive promotional communication and newsletters from GLSPL.
        You can opt out either by unsubscribing through the facility available
        on the Site or by contacting &nbsp;GLSPL.</span
      >
    </p>
    <p class="c4"><span class="c1"></span></p>
    <ol class="c3 lst-kix_list_1-0" start="5">
      <li class="c10 li-bullet-0"><span class="c2">Information</span></li>
    </ol>
    <p class="c4"><span class="c8 c5"></span></p>
    <p class="c0">
      <span class="c1"
        >GLSPL has taken all reasonable efforts to ensure that the Information
        provided on the Site is accurate and reliable. The contents of the
        services are developed on the concepts covered in the structured
        curriculum syllabus prescribed for students of various courses. The
        usage of the Site is not intended as a substitution to the curriculum
        based education provided by the educational institutions but is intended
        to supplement the same by explaining and presenting the concepts in a
        manner enabling easy understanding.
      </span>
    </p>
    <p class="c0 c13"><span class="c1"></span></p>
    <p class="c0">
      <span class="c1"
        >It is possible that the methods used to explain or deliver a particular
        topic in the curriculum may differ from what is taught by another
        educational institution or tutor. This does not imply that GLSPL in any
        way endorse, approve or disapprove of any other method employed.</span
      >
    </p>
    <p class="c0 c13"><span class="c1"></span></p>
    <p class="c0">
      <span class="c1"
        >It is expressly clarified that by obtaining the services being offered
        through the Site by GLSPL does not in any manner guarantee admission to
        any educational institutions or passing of any exams or achievement of
        any specified percentage of marks in any examinations.</span
      >
    </p>
    <p class="c4"><span class="c1"></span></p>
    <p class="c0">
      <span class="c5"
        >You also accept that all of the Information is provided on an
        &ldquo;</span
      ><span class="c5 c9">as is</span
      ><span class="c1"
        >&rdquo; basis and is provided without any warranty, representation or
        condition of any kind, whether express, implied, statutory or otherwise.
        You assume all responsibility and risk for your use of or reliance upon
        the Information and the Site or any other material accessed through the
        Site, including via any Linked Website.</span
      >
    </p>
    <p class="c4"><span class="c1"></span></p>
    <p class="c0">
      <span class="c1"
        >While GLSPL seeks to ensure that the Information is reliable and
        accurate, errors and omissions may occur and therefore, GLSPL does not
        make or give any representation or warranty (express or implied) of any
        kind as to any matter relating to the Site and any Linked Website,
        including without limitation, as to merchantability, non-infringement of
        intellectual property rights or fitness for any particular purpose, and
        specifically disclaims any such representation or warranty.</span
      >
    </p>
    <p class="c4"><span class="c1"></span></p>
    <p class="c21 c15 c23">
      <span class="c1">In particular GLSPL does not warrant that:</span>
    </p>
    <p class="c4"><span class="c1"></span></p>
    <ul class="c3 lst-kix_list_2-0">
      <li class="c6 li-bullet-1">
        <span class="c1"
          >the Information or any other material accessed through the Site, is
          reliable, accurate or complete;
        </span>
      </li>
      <li class="c6 li-bullet-1">
        <span class="c1"
          >Your access to Information or any other material accessed through the
          Site, including via any Linked Website, will be uninterrupted, timely
          or secure;</span
        >
      </li>
      <li class="c6 li-bullet-1">
        <span class="c1"
          >GLSPL will be liable for any loss (direct or indirect) resulting from
          any action taken or reliance made by You on the Information or any
          other material accessed through the Site, including via any Linked
          Website. You should make your own inquiries and seek independent
          professional advice before acting or relying on any such information
          or material; the Information on the Site will remain unchanged. GLSPL
          may from time to time update, modify or add to the Information,
          including these Conditions, and absolutely reserves the right to make
          such changes without any obligation to notify past, current or
          prospective users of the Site. Unless otherwise specified to the
          contrary, all new Information shall be subject to these Conditions;
          and</span
        >
      </li>
      <li class="c6 li-bullet-1">
        <span class="c1"
          >the Site will operate continuously or without interruption or be
          error free. In addition, GLSPL may also suspend or discontinue any
          aspect of the Site at any time without being liable for any direct or
          indirect loss as a result of such action.</span
        >
      </li>
    </ul>
    <p class="c4"><span class="c1"></span></p>
    <ol class="c3 lst-kix_list_1-0" start="6">
      <li class="c10 li-bullet-0">
        <span class="c2">Liability Disclaimer &amp; Exclusion</span>
      </li>
    </ol>
    <p class="c4"><span class="c5 c8"></span></p>
    <p class="c0">
      <span class="c1"
        >In no event shall GLSPL be responsible for any direct, indirect,
        special, incidental or consequential loss or damage, however arising and
        whether in contract, tort or otherwise, which you may suffer in
        connection with or arising out of:</span
      >
    </p>
    <p class="c4"><span class="c1"></span></p>
    <ul class="c3 lst-kix_list_2-0">
      <li class="c6 li-bullet-1">
        <span class="c1">your use of the Site or any Linked Website; or</span>
      </li>
      <li class="c6 li-bullet-1">
        <span class="c1"
          >your use of or reliance on Information or any other material accessed
          through the Site, including via any Linked Website, including without
          limitation, loss or damage by way personal injury, failure to obtain a
          certain percentage of marks in an examination, gaining admission in
          any particular school or college, or placement in any organisations
          entities for employment, or loss of information.</span
        >
      </li>
    </ul>
    <p class="c4"><span class="c1"></span></p>
    <p class="c0">
      <span class="c1"
        >These Conditions do not purport to exclude liability arising by any
        applicable law if, and to the extent, such liability cannot be lawfully
        excluded. However, to the extent permitted by law, all warranties, terms
        or conditions which would otherwise be implied into these Conditions are
        hereby excluded.</span
      >
    </p>
    <p class="c4"><span class="c1"></span></p>
    <p class="c0">
      <span class="c1"
        >Where applicable law implies any warranty, term or condition, and that
        law prohibits exclusion or modification of the application of or the
        liability of GLSPL under any such warranty, term or condition, then the
        liability of GLSPL shall include liability for that warranty, term or
        condition, but such liability will be limited to, if the breach relates
        to services, the supply of the services again or payment of the cost of
        having the services supplied again.</span
      >
    </p>
    <p class="c4"><span class="c1"></span></p>
    <ol class="c3 lst-kix_list_1-0" start="7">
      <li class="c10 li-bullet-0"><span class="c2">Specific Warnings</span></li>
    </ol>
    <p class="c4"><span class="c8 c5"></span></p>
    <p class="c0">
      <span class="c1"
        >You must ensure that your access to and your use of the Site is not
        illegal or prohibited by laws which apply to you.</span
      >
    </p>
    <p class="c4"><span class="c1"></span></p>
    <p class="c0">
      <span class="c1"
        >You must take your own precautions that the process which you employ
        for accessing the Site does not expose you to the risk of viruses,
        malicious computer code, computer program routines or processes or other
        forms of interference which may damage your own computer system. Without
        limitation, you are responsible for ensuring that your own computer
        system meets all relevant technical specifications necessary to use the
        Site and is compatible with the Site. For the removal of doubt, GLSPL
        does not accept responsibility for any interference or damage to your
        own computer system which arises in connection with your use of the Site
        or any Linked Website.</span
      >
    </p>
    <p class="c4"><span class="c1"></span></p>
    <p class="c0">
      <span class="c1"
        >GLSPL does not guarantee or warrant that any material available for
        downloading from the Site or any Linked Website will be free from any
        virus, infection or other condition which has contaminating or
        destructive properties. You are responsible for taking sufficient
        precautions and checks to satisfy your own particular requirements for
        accuracy of data input and output.</span
      >
    </p>
    <p class="c4"><span class="c1"></span></p>
    <p class="c0">
      <span class="c1"
        >You must ensure that any information which you provide to GLSPL is
        accurate and complete and does not contain any virus, malicious computer
        code, computer program routine or process or other form of interference
        which may damage the computer system of GLSPL or the Information, or
        which may detrimentally interfere with or surreptitiously intercept or
        expropriate any GLSPL system, data or information.</span
      >
    </p>
    <p class="c4"><span class="c8 c5"></span></p>
    <ol class="c3 lst-kix_list_1-0" start="8">
      <li class="c10 li-bullet-0"><span class="c2">Linked Websites</span></li>
    </ol>
    <p class="c4"><span class="c1"></span></p>
    <p class="c0">
      <span class="c5"
        >The Site may contain links to other websites (each a &ldquo;</span
      ><span class="c14">Linked Website</span
      ><span class="c1"
        >&rdquo;). These links are provided for convenience only and may not
        remain current or be maintained.</span
      >
    </p>
    <p class="c4"><span class="c1"></span></p>
    <p class="c0">
      <span class="c1"
        >You acknowledge and agree that GLSPL does not have any control over the
        content or availability of the Linked Websites and accepts no
        responsibility for the content, privacy practices or any other aspect of
        the Linked Websites.</span
      >
    </p>
    <p class="c4"><span class="c1"></span></p>
    <p class="c0">
      <span class="c1"
        >Links to the Linked Websites should not be construed as an endorsement,
        approval or recommendation by GLSPL of the owners or operators of the
        Linked Websites, or of any information, graphics, materials, products or
        services referred to, provided or contained on the Linked
        Websites.</span
      >
    </p>
    <p class="c4"><span class="c1"></span></p>
    <ol class="c3 lst-kix_list_1-0" start="9">
      <li class="c10 li-bullet-0">
        <span class="c2">User account, Password and Security</span>
      </li>
    </ol>
    <p class="c4"><span class="c1"></span></p>
    <p class="c0">
      <span class="c1"
        >The user is entirely responsible for maintaining the confidentiality of
        the user&rsquo;s password and account, and for any and all activities
        which occur under the user&rsquo;s account or password. The user agrees
        to immediately notify GLSPL of any unauthorised use of the user&rsquo;s
        account, password, or any other breach of security known to the user.
        GLSPL will not be liable for any loss that the user may incur as a
        result of someone else using or accessing the user&rsquo;s password or
        account, either with or without the user&rsquo;s knowledge. However the
        user may be held liable for losses incurred by GLSPL or any other party
        as a result of someone else using or accessing the user&rsquo;s password
        or account. The user will not use anyone else&rsquo;s account at any
        time without the permission of the account holder. The user may change
        his/her password or profile by following instructions on the Site.</span
      >
    </p>
    <p class="c4"><span class="c1"></span></p>
    <ol class="c3 lst-kix_list_1-0" start="10">
      <li class="c10 li-bullet-0">
        <span class="c2">Privacy</span><span class="c1">&nbsp;</span
        ><span class="c2">Policy</span>
      </li>
    </ol>
    <p class="c4 c16"><span class="c1"></span></p>
    <p class="c0">
      <span class="c1"
        >GLSPL will use and protect your data, such as your name and address, in
        accordance with the GLSPL Privacy Policy, the contents of which are
        incorporated by reference into the Conditions. Please be aware that your
        browser must be enabled to accept cookies in order for you to use
        certain Information and services or to purchase from the Site. The GLSPL
        Privacy Policy does not apply to the Linked Websites.</span
      >
    </p>
    <p class="c7 c15"><span class="c1"></span></p>
    <ol class="c3 lst-kix_list_1-0" start="11">
      <li class="c10 li-bullet-0">
        <span class="c8 c14">Subscriptions, Cancellations and Refunds</span>
      </li>
    </ol>
    <p class="c4 c16"><span class="c8 c5"></span></p>
    <p class="c0">
      <span class="c1"
        >Subscriptions and cancellations are governed by GLSPL&rsquo;s
        Subscriptions and Cancellation Policy, the contents of which are
        incorporated by reference into the Conditions.
      </span>
    </p>
    <p class="c0 c13"><span class="c1"></span></p>
    <ol class="c3 lst-kix_list_1-0" start="12">
      <li class="c10 li-bullet-0"><span class="c2">Indemnification</span></li>
    </ol>
    <p class="c4"><span class="c8 c5"></span></p>
    <p class="c0">
      <span class="c1"
        >You agree to indemnify, defend and hold GLSPL and its subsidiaries,
        affiliates, officers, directors, agents and employees harmless from any
        claim or demand, made by any third party due to or arising out of your
        breach of the Conditions, this agreement or the documents it
        incorporates by reference, or your violation of any law or the rights of
        a third party. GLSPL&rsquo;s failure to act with respect to a breach by
        you or others does not waive its right to act with respect to subsequent
        or similar breaches.</span
      >
    </p>
    <p class="c4"><span class="c1"></span></p>
    <ol class="c3 lst-kix_list_1-0" start="13">
      <li class="c10 li-bullet-0"><span class="c2">Communication</span></li>
    </ol>
    <p class="c4"><span class="c8 c5"></span></p>
    <p class="c0">
      <span class="c5"
        >With respect to any communication made to GLSPL on or through the Site
        including, without limitation, feedback, questions, comments and
        suggestions (the &ldquo;</span
      ><span class="c14">Communications</span><span class="c1">&rdquo;): </span>
    </p>
    <p class="c4"><span class="c1"></span></p>
    <ul class="c3 lst-kix_list_2-0">
      <li class="c6 li-bullet-1">
        <span class="c1"
          >No right of confidentiality shall apply to the Communications and
          GLSPL shall have no obligation to protect the Communications from
          disclosure;
        </span>
      </li>
      <li class="c6 li-bullet-1">
        <span class="c1"
          >GLSPL shall be free to reproduce, use, disclose and distribute the
          Communications to others; and
        </span>
      </li>
      <li class="c6 li-bullet-2">
        <span class="c1"
          >GLSPL shall be free to use any ideas, concepts, know-how or
          techniques contained in the Communications for any purpose whatsoever
          including, without limitation, the development, production and
          marketing of products and services that incorporate such
          information.</span
        >
      </li>
    </ul>
    <p class="c4"><span class="c1"></span></p>
    <ol class="c3 lst-kix_list_1-0" start="14">
      <li class="c10 li-bullet-0"><span class="c2">Payment Mechanism</span></li>
    </ol>
    <p class="c4 c16"><span class="c1"></span></p>
    <p class="c0">
      <span class="c1"
        >GLSPL is offering various payment mechanisms on the SIte for the
        convenience of its Users. All information submitted directly to GLSPL
        with respect to payments will be governed by GLSPL&rsquo;s Privacy
        Policy.
      </span>
    </p>
    <p class="c4 c16"><span class="c1"></span></p>
    <p class="c0">
      <span class="c1"
        >The payment gateways are incorporated onto the Site as Linked Websites
        and shall be considered a Third-Party Service. Please note that using
        any payment mechanism will be governed by the terms and conditions
        applicable to that particular payment gateway and shall not be governed
        by these Conditions or GLSPL&rsquo;s Privacy Policy. By using the Third
        Party Service, you consent to be bound by the terms and conditions
        applicable to the use of such Third Party Service.
      </span>
    </p>
    <p class="c4 c16"><span class="c2"></span></p>
    <ol class="c3 lst-kix_list_1-0" start="15">
      <li class="c10 li-bullet-0"><span class="c2">General</span></li>
    </ol>
    <p class="c4"><span class="c1"></span></p>
    <p class="c0">
      <span class="c1"
        >If any provisions of these Conditions are found to be illegal, invalid
        or otherwise unenforceable by reason of the laws of any state or country
        in which these terms are intended to be effective, then to the extent
        and within the jurisdiction in which that term is illegal, invalid or
        unenforceable, it shall be severed and deleted from these terms and the
        remaining terms shall survive, remain in full force and effect.</span
      >
    </p>
    <p class="c0 c13"><span class="c1"></span></p>
    <p class="c0">
      <span class="c1"
        >GLSPL does not accept any liability for any failure by GLSPL to comply
        with these Conditions where such failure is due to circumstances beyond
        its reasonable control.</span
      >
    </p>
    <p class="c0 c13"><span class="c1"></span></p>
    <p class="c0">
      <span class="c1"
        >If there are any inconsistencies between the provisions of these
        Conditions, the Privacy Policy and the Subscription and Cancellation
        Policy, the terms of these Conditions shall prevail.</span
      >
    </p>
    <p class="c4"><span class="c1"></span></p>
    <p class="c0">
      <span class="c1"
        >If GLSPL waives any rights available to it under these Conditions on
        one occasion, it does not mean that those rights will automatically be
        waived on any other occasion.</span
      >
    </p>
    <p class="c4"><span class="c1"></span></p>
    <p class="c0">
      <span class="c1"
        >You are completely responsible for all charges, fees, duties, taxes and
        assessments arising out of the use of the Site.</span
      >
    </p>
    <p class="c4"><span class="c1"></span></p>
    <p class="c0">
      <span class="c1"
        >The section titles in the document are solely used for the convenience
        of the parties and have no legal or contractual significance.</span
      >
    </p>
    <p class="c0 c13"><span class="c1"></span></p>
    <p class="c0">
      <span class="c1"
        >These terms shall be governed by and interpreted in accordance with the
        laws of India and you agree to submit to the exclusive jurisdiction of
        the courts of Bengaluru.</span
      >
    </p>
    <p class="c4"><span class="c1"></span></p>
    <ol class="c3 lst-kix_list_1-0" start="16">
      <li class="c10 li-bullet-0"><span class="c2">Return to Site</span></li>
    </ol>
    <p class="c4"><span class="c8 c5"></span></p>
    <p class="c15 c16 c21">
      <span class="c1"
        >To return to the Site, click where indicated. By doing so, you
        acknowledge that you have read, understood and accepted these
        Conditions.</span
      >
    </p>
    <p class="c4"><span class="c1"></span></p>
    <p class="c4"><span class="c1"></span></p>
    <p class="c15 c13 c19"><span class="c22"></span></p>
  </v-container>
</template>

<script>
export default {
  name: "TermsAndConditions",
};
</script>

<style scoped>
ol.lst-kix_list_1-3 {
  list-style-type: none;
}

ol.lst-kix_list_1-4 {
  list-style-type: none;
}

.lst-kix_list_2-6 > li:before {
  content: "\0025cf  ";
}

.lst-kix_list_2-7 > li:before {
  content: "o  ";
}

ol.lst-kix_list_1-5 {
  list-style-type: none;
}

ol.lst-kix_list_1-6 {
  list-style-type: none;
}

ol.lst-kix_list_1-0 {
  list-style-type: none;
}

.lst-kix_list_2-4 > li:before {
  content: "o  ";
}

.lst-kix_list_2-5 > li:before {
  content: "\0025aa  ";
}

.lst-kix_list_2-8 > li:before {
  content: "\0025aa  ";
}

ol.lst-kix_list_1-1 {
  list-style-type: none;
}

ol.lst-kix_list_1-2 {
  list-style-type: none;
}

.lst-kix_list_1-1 > li {
  counter-increment: lst-ctn-kix_list_1-1;
}

ol.lst-kix_list_1-8.start {
  counter-reset: lst-ctn-kix_list_1-8 0;
}

ol.lst-kix_list_1-5.start {
  counter-reset: lst-ctn-kix_list_1-5 0;
}

ol.lst-kix_list_1-7 {
  list-style-type: none;
}

.lst-kix_list_1-7 > li {
  counter-increment: lst-ctn-kix_list_1-7;
}

ol.lst-kix_list_1-8 {
  list-style-type: none;
}

li.li-bullet-1:before {
  margin-left: -21.2pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 21.2pt;
}

ol.lst-kix_list_1-7.start {
  counter-reset: lst-ctn-kix_list_1-7 0;
}

.lst-kix_list_1-2 > li {
  counter-increment: lst-ctn-kix_list_1-2;
}

.lst-kix_list_1-5 > li {
  counter-increment: lst-ctn-kix_list_1-5;
}

.lst-kix_list_1-8 > li {
  counter-increment: lst-ctn-kix_list_1-8;
}

ol.lst-kix_list_1-4.start {
  counter-reset: lst-ctn-kix_list_1-4 0;
}

ol.lst-kix_list_1-1.start {
  counter-reset: lst-ctn-kix_list_1-1 0;
}

.lst-kix_list_1-4 > li {
  counter-increment: lst-ctn-kix_list_1-4;
}

ol.lst-kix_list_1-6.start {
  counter-reset: lst-ctn-kix_list_1-6 0;
}

ol.lst-kix_list_1-3.start {
  counter-reset: lst-ctn-kix_list_1-3 0;
}

ul.lst-kix_list_2-8 {
  list-style-type: none;
}

ol.lst-kix_list_1-2.start {
  counter-reset: lst-ctn-kix_list_1-2 0;
}

ul.lst-kix_list_2-2 {
  list-style-type: none;
}

.lst-kix_list_1-0 > li:before {
  content: "" counter(lst-ctn-kix_list_1-0, decimal) ". ";
}

ul.lst-kix_list_2-3 {
  list-style-type: none;
}

ul.lst-kix_list_2-0 {
  list-style-type: none;
}

ul.lst-kix_list_2-1 {
  list-style-type: none;
}

ul.lst-kix_list_2-6 {
  list-style-type: none;
}

.lst-kix_list_1-1 > li:before {
  content: "" counter(lst-ctn-kix_list_1-1, lower-latin) ". ";
}

.lst-kix_list_1-2 > li:before {
  content: "" counter(lst-ctn-kix_list_1-2, lower-roman) ". ";
}

ul.lst-kix_list_2-7 {
  list-style-type: none;
}

ul.lst-kix_list_2-4 {
  list-style-type: none;
}

ul.lst-kix_list_2-5 {
  list-style-type: none;
}

.lst-kix_list_1-3 > li:before {
  content: "" counter(lst-ctn-kix_list_1-3, decimal) ". ";
}

.lst-kix_list_1-4 > li:before {
  content: "" counter(lst-ctn-kix_list_1-4, lower-latin) ". ";
}

ol.lst-kix_list_1-0.start {
  counter-reset: lst-ctn-kix_list_1-0 0;
}

.lst-kix_list_1-0 > li {
  counter-increment: lst-ctn-kix_list_1-0;
}

.lst-kix_list_1-6 > li {
  counter-increment: lst-ctn-kix_list_1-6;
}

.lst-kix_list_1-7 > li:before {
  content: "" counter(lst-ctn-kix_list_1-7, lower-latin) ". ";
}

.lst-kix_list_1-3 > li {
  counter-increment: lst-ctn-kix_list_1-3;
}

.lst-kix_list_1-5 > li:before {
  content: "" counter(lst-ctn-kix_list_1-5, lower-roman) ". ";
}

.lst-kix_list_1-6 > li:before {
  content: "" counter(lst-ctn-kix_list_1-6, decimal) ". ";
}

li.li-bullet-0:before {
  margin-left: -35.5pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 35.5pt;
}

li.li-bullet-2:before {
  margin-left: -21.2pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 21.2pt;
}

.lst-kix_list_2-0 > li:before {
  content: "\0025cf  ";
}

.lst-kix_list_2-1 > li:before {
  content: "o  ";
}

.lst-kix_list_1-8 > li:before {
  content: "" counter(lst-ctn-kix_list_1-8, lower-roman) ". ";
}

.lst-kix_list_2-2 > li:before {
  content: "\0025aa  ";
}

.lst-kix_list_2-3 > li:before {
  content: "\0025cf  ";
}

ol {
  margin: 0;
  padding: 0;
}

table td,
table th {
  padding: 0;
}

.c6 {
  margin-left: 53.5pt;
  padding-top: 0pt;
  padding-left: 3.2pt;
  padding-bottom: 0pt;
  line-height: 2;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c10 {
  margin-left: 18pt;
  padding-top: 0pt;
  padding-left: 17.5pt;
  padding-bottom: 0pt;
  line-height: 2;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.c22 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: "Calibri";
  font-style: normal;
}

.c0 {
  margin-left: 35.5pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 2;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c1 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: "Arial";
  font-style: normal;
}

.c11 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 2;
  orphans: 2;
  widows: 2;
  text-align: center;
  height: 11pt;
}

.c4 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 2;
  orphans: 2;
  widows: 2;
  text-align: left;
  height: 11pt;
}

.c2 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: "Arial";
  font-style: normal;
}

.c8 {
  -webkit-text-decoration-skip: none;
  text-decoration: underline;
  vertical-align: baseline;
  text-decoration-skip-ink: none;
  font-size: 11pt;
  font-style: normal;
}

.c7 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 2;
  text-align: justify;
  height: 11pt;
}

.c18 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 2;
  text-align: center;
}

.c19 {
  padding-top: 0pt;
  padding-bottom: 10pt;
  line-height: 1.1500000000000001;
  text-align: left;
}

.c21 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 2;
  text-align: left;
}

.c14 {
  font-family: "Arial";
  color: #000000;
  font-weight: 700;
}

.c5 {
  font-family: "Arial";
  color: #000000;
  font-weight: 400;
}

.c15 {
  orphans: 2;
  widows: 2;
}

.c20 {
  max-width: 468pt;
  padding: 72pt 72pt 72pt 72pt;
}

.c3 {
  padding: 0;
  margin: 0;
}

.c17 {
  background-color: #ffffff;
}

.c24 {
  color: #000000;
}

.c16 {
  margin-left: 35.5pt;
}

.c13 {
  height: 11pt;
}

.c12 {
  margin-left: 56.7pt;
}

.c23 {
  text-indent: 35.5pt;
}

.c9 {
  font-style: italic;
}

.title {
  padding-top: 24pt;
  color: #000000;
  font-weight: 700;
  font-size: 36pt;
  padding-bottom: 6pt;
  font-family: "Calibri";
  line-height: 1.1500000000000001;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.subtitle {
  padding-top: 18pt;
  color: #666666;
  font-size: 24pt;
  padding-bottom: 4pt;
  font-family: "Georgia";
  line-height: 1.1500000000000001;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}

li {
  color: #000000;
  font-size: 11pt;
  font-family: "Calibri";
}

p {
  margin: 0;
  color: #000000;
  font-size: 11pt;
  font-family: "Calibri";
}

h1 {
  padding-top: 24pt;
  color: #000000;
  font-weight: 700;
  font-size: 24pt;
  padding-bottom: 6pt;
  font-family: "Calibri";
  line-height: 1.1500000000000001;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h2 {
  padding-top: 18pt;
  color: #000000;
  font-weight: 700;
  font-size: 18pt;
  padding-bottom: 4pt;
  font-family: "Calibri";
  line-height: 1.1500000000000001;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h3 {
  padding-top: 14pt;
  color: #000000;
  font-weight: 700;
  font-size: 14pt;
  padding-bottom: 4pt;
  font-family: "Calibri";
  line-height: 1.1500000000000001;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h4 {
  padding-top: 12pt;
  color: #000000;
  font-weight: 700;
  font-size: 12pt;
  padding-bottom: 2pt;
  font-family: "Calibri";
  line-height: 1.1500000000000001;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h5 {
  padding-top: 11pt;
  color: #000000;
  font-weight: 700;
  font-size: 11pt;
  padding-bottom: 2pt;
  font-family: "Calibri";
  line-height: 1.1500000000000001;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h6 {
  padding-top: 10pt;
  color: #000000;
  font-weight: 700;
  font-size: 10pt;
  padding-bottom: 2pt;
  font-family: "Calibri";
  line-height: 1.1500000000000001;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
</style>
<template>
  <v-dialog v-model="show" persistent max-width="350">
    <v-form ref="otpForm" @submit.prevent="verify()">
      <v-card>
        <v-card-title> Enter OTP </v-card-title>
        <v-card-text>
          <span>OTP has been sent to {{ hashNumber(phoneNumber) }}</span>
          <v-text-field
            solo
            label="Enter OTP"
            class="mt-3"
            :rules="otpRules"
            v-model="otp"
          ></v-text-field>
          <v-card-actions>
            <v-btn class="primary">Verify and Login</v-btn>
            <v-btn class="white--text" color="red" text @click="show = false"
              >Cancel</v-btn
            >
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { verifyOTP } from "../../services/Auth";
export default {
  name: "OTP",
  props: ["visible", "phoneNumber"],
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) this.$emit("close");
      },
    },
  },
  data() {
    return {
      otp: "",
      otpRules: (v) => !!v || "OTP is required",
    };
  },
  methods: {
    async verify() {
      if (this.$refs.otpForm.validate()) {
        const response = await verifyOTP(this.otp);
        if (response.status === 400) {
          this.$toast.error(response.message, {
            position: "top",
          });
          return;
        }
        this.show = false;
      }
    },
    hashNumber(phoneNumber) {
      if (phoneNumber === undefined) return;
      return `${phoneNumber.substring(0, 2)}XXXXXX${phoneNumber.substring(
        8,
        phoneNumber.length
      )}`;
    },
  },
};
</script>

<style scoped>
</style>
<template>
  <div class="wrapper">
    <Header></Header>
    <div
      class="buy__course"
      v-if="
        $store.state.user.currentUser === '' ||
        ($store.state.user.currentUser !== '' &&
          $store.state.user.currentUser.purchases.length === 0)
      "
    >
      <h2>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="black"
              v-bind="attrs"
              v-on="on"
              link
              to="/courses"
              ><v-icon>mdi-arrow-left</v-icon></v-btn
            >
          </template>
          <span>Back to modules</span>
        </v-tooltip>
        Buy Complete Course
      </h2>
      <v-btn color="primary" @click="buy('course', $route.params.id)"
        >Buy Now</v-btn
      >
    </div>
    <v-row class="mt-5">
      <v-col cols="12" md="8">
        <div class="player__section" v-if="lessons.lessons !== undefined">
          <video
            controls
            controlsList="nodownload"
            :src="lessons.lessons[0].videoURL"
            autoplay
            class=""
            v-if="!lessons.lessons[0].locked && currentUser !== ''"
            @ended="handleVideoCount(lessons.lessons[0])"
            @contextmenu.prevent=""
          ></video>
          <div class="videoThumbnail" v-else>
            <img :src="lessons.lessons[0].bannerImg" alt="Gyanathon" />
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="float-right locked__on_screen"
                  v-bind="attrs"
                  v-on="on"
                  color="primary"
                  v-if="
                    currentUser !== '' &&
                    currentUser.purchases.length !== 0 &&
                    !lessons.lessons[0].extended
                  "
                  @click="
                    buy(
                      'topic',
                      lessons.lessons[0].id,
                      lessons.lessons[0].title
                    )
                  "
                  >Purchase<v-icon size="20">mdi-lock</v-icon></v-btn
                >
              </template>
              <span
                >You have exceeded with the maximum 4 viewing quota. You can buy
                2 more quota.</span
              >
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  class="float-right locked__on_screen"
                  v-bind="attrs"
                  v-on="on"
                  color="primary"
                  v-if="
                    currentUser !== '' &&
                    currentUser.purchases.length !== 0 &&
                    lessons.lessons[0].extendedCount == 2
                  "
                  ><v-icon size="20">mdi-lock</v-icon></v-btn
                >
              </template>
              <span
                >You have exhausted with the maximum 4 viewing quota and 2 bonus
                quota. You can't view this video again.</span
              >
            </v-tooltip>
          </div>
          <h2>{{ lessons.lessons[0].title }}</h2>
          <p>
            {{ lessons.lessons[0].description }}
          </p>
        </div>
      </v-col>
      <v-col cols="12" md="4">
        <h2>Upcomings</h2>
        <div class="upcomings mt-3">
          <list-skeleton v-if="!loaded"></list-skeleton>
          <div
            class="module__row"
            v-for="(lesson, index) in lessons.lessons"
            :key="index"
            v-show="index !== 0"
            @click="view(lesson.id)"
          >
            <div class="thumbnail">
              <img :src="lesson.bannerImg" :alt="lesson.title" />
            </div>
            <div class="module__details px-2">
              <h3>
                {{ lesson.title }}
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      class="float-right"
                      v-bind="attrs"
                      v-on="on"
                      v-if="lesson.locked"
                      ><v-icon>mdi-lock</v-icon></v-btn
                    >
                  </template>
                  <span>Purchase to unlock</span>
                </v-tooltip>
              </h3>
              <p>{{ truncateParagraph(50, lesson.description) }}</p>
              <span
                v-if="currentUser !== '' && currentUser.purchases.length !== 0"
                class="duration"
                >Duration: {{ lesson.duration }}</span
              >
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import axios from "axios";
import _helpers from "../mixins/_helpers";
import buy from "../mixins/buy";
import { mapState } from "vuex";
import ListSkeleton from "../components/Skeletons/ListItems";
import { auth } from "../config/firebase";

export default {
  components: { Header, ListSkeleton },
  name: "Watch",
  data() {
    return {
      lessons: [],
      loaded: false,
    };
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.user.currentUser,
    }),
  },
  mixins: [_helpers, buy],
  created() {
    if (this.$store.state.user.currentUser === "") this.getLessons();
    else if (
      this.$store.state.user.currentUser !== "" &&
      this.$store.state.user.currentUser.purchases.length === 0
    )
      this.getLessons();
    else this.getUserCourses();
  },
  // updated() {
  //   this.getUserCourses();
  // },
  methods: {
    async getLessons() {
      const response = await axios.post(
        `${this.$API_BASE_URL}/module/${this.$route.params.id}`
      );
      this.lessons = response.data;
      this.loaded = true;
    },
    async getUserCourses() {
      try {
        const idToken = await auth.currentUser.getIdToken();
        const response = await axios.post(
          `${this.$API_BASE_URL}/module/${this.$route.params.id}`,
          {
            idToken: idToken,
          }
        );
        this.lessons = response.data;
        this.loaded = true;
      } catch (error) {
        this.$toast.error(error, {
          position: "top",
        });
      }
    },
    async view(id) {
      this.$store.dispatch("setLoader", true);
      if (this.currentUser === "" || this.currentUser.purchases.length === 0) {
        setTimeout(() => {
          // let idx = this.lessons.lessons.findIndex(
          //   (lesson) => lesson.id === id
          // );
          this.lessons.lessons.push(this.lessons.lessons[0]);
          this.lessons.lessons.shift();
          // this.lessons.lessons.unshift(this.lessons.lessons[idx]);
          this.$store.dispatch("setLoader", false);
        }, 1000);
        return;
      }

      const index = this.lessons.lessons.findIndex(
        (lesson) => lesson.videoURL !== ""
      );
      this.lessons.lessons[index].videoURL = "";
      try {
        const idToken = await auth.currentUser.getIdToken();
        const response = await axios.post(
          `${this.$API_BASE_URL}/video/view/${id}`,
          {
            idToken: idToken,
          }
        );
        const delLessonIndex = this.lessons.lessons.findIndex(
          (lesson) => lesson.id === id
        );
        this.lessons.lessons.splice(delLessonIndex, 1);
        this.lessons.lessons.unshift(response.data.data);
        this.$store.dispatch("setLoader", false);
      } catch (error) {
        this.$toast.error(error, {
          position: "top",
        });
        this.$store.dispatch("setLoader", false);
      }
    },
    async handleVideoCount(lesson) {
      try {
        const idToken = await auth.currentUser.getIdToken();
        const response = await axios.post(
          `${this.$API_BASE_URL}/video/${this.$route.params.id}`,
          {
            idToken: idToken,
            id: lesson.id,
          }
        );
        // const idx = this.lessons.lessons.findIndex(
        //   (lsn) => lsn.id === lesson.id
        // );
        this.lessons.lessons[0] = response.data.data;
      } catch (error) {
        this.$toast.error(error, {
          position: "top",
        });
      }
    },
  },
};
</script>

<style scoped>
.wrapper {
  padding: 15px 20px;
}

.buy__course {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 80px;
  background-color: #a6d4d1;
  padding: 0 15px;
  border-radius: 10px;
}

.player__section {
  width: 100%;
}

video {
  width: 100%;
  height: 100%;
  background-size: cover;
  outline: none;
  border: 1px solid #cecece;
  height: 400px;
  border-radius: 10px;
}

.videoThumbnail {
  padding: 5px;
  justify-content: center;
  display: flex;
  width: 100%;
  height: 100%;
  background-size: cover;
  outline: none;
  border: 1px solid #cecece;
  height: 400px;
  border-radius: 10px;
  position: relative;
}

.upcomings {
  width: 100%;
  border: 1px solid #cecece;
  border-radius: 10px;
  height: auto;
}

.module__row {
  width: 100%;
  padding: 5px 15px;
  border-bottom: 1px solid #cecece;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.thumbnail > img {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.duration {
  color: gray;
  font-size: 14px;
  font-weight: bold;
  float: right;
}

.locked__on_screen {
  position: absolute;
  right: 15px;
  top: 15px;
}

@media only screen and (max-width: 600px) {
  img {
    width: 100%;
  }

  video {
    height: 250px;
    object-fit: fill;
    width: inherit;
    height: inherit;
    transform: scale(1.10);
  }

  h2 {
    margin-top: 5px;
  }
}
</style>
<template>
  <v-row :class="isSticky ? 'sticky' : ''">
    <v-col cols="12" md="4" class="not__mbl">
      <a href="/">
        <img :src="require(`../assets/images/${logo}`)" alt="Gyanathon" />
      </a>
    </v-col>
    <v-col cols="12" md="8">
      <div class="d-flex justify-space-around user__info">
        <img
          class="logo"
          :src="require(`../assets/images/${logo}`)"
          alt="Gyanathon"
        />
        <v-menu offset-y v-if="currentUser !== ''">
          <template v-slot:activator="{ on, attrs }">
            <v-avatar color="red" v-bind="attrs" v-on="on">
              <span class="white--text headline">{{
                currentUser.displayName.charAt(0)
              }}</span>
            </v-avatar>
            <h2>{{ currentUser.displayName }}</h2>
          </template>
          <v-list>
            <v-list-item @click="dialog = true">
              <v-list-item-title>Profile</v-list-item-title>
            </v-list-item>
            <!-- <v-list-item>
              <v-list-item-title>My Courses</v-list-item-title>
            </v-list-item> -->
            <v-list-item @click="logout()">
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <div class="btn__row pa-5" v-else>
          <v-btn color="primary" class="mr-5" link to="/">Login</v-btn>
          <v-btn color="primary" link to="/signup">Signup</v-btn>
        </div>
      </div>
    </v-col>
    <profile :visible="dialog" @close="dialog = false"></profile>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import { logo } from "../constants";
import { Logout } from "../services/Auth";
import Profile from "./Modals/Profile.vue";

export default {
  components: { Profile },
  name: "Header",
  computed: {
    logo: function () {
      return logo;
    },
    ...mapState({ currentUser: (state) => state.user.currentUser }),
  },
  data() {
    return {
      isSticky: false,
      dialog: false,
    };
  },
  methods: {
    async logout() {
      this.$store.dispatch("setLoader", true);
      const response = await Logout();
      if (response.status === 400) {
        this.$toast.error(response.message, {
          position: "top",
        });
        this.$store.dispatch("setLoader", false);
        return;
      }
      this.$store.dispatch("setLoader", false);
      this.$router.push("/");
      setTimeout(() => {
        window.location.reload();
      });
    },
  },
  // mounted() {
  //   window.addEventListener("scroll", this.handleScroll);
  // },
  // beforeDestroy() {
  //   window.removeEventListener("scroll", this.handleScroll);
  // },
  // methods: {
  //   handleScroll() {
  //     const scrollPosition = window.scrollY;
  //     console.log(scrollPosition);
  //     scrollPosition > 60 ? (this.isSticky = true) : (this.isSticky = false);
  //   },
  // },
};
</script>

<style scoped>
.user__info {
  width: 30%;
  float: right;
  align-items: center;
}

.sticky {
  position: fixed;
  top: 0;
  background: #fff;
  margin-top: 0 !important;
  z-index: 999;
  width: 100%;
  -webkit-animation: 0.95s ease-in-out 0s normal none 1 running fadeInDown;
  animation: 0.95s ease-in-out 0s normal none 1 running fadeInDown;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.logo {
  display: none;
}

@media only screen and (max-width: 600px) {
  .not__mbl {
    display: none;
  }

  .logo {
    width: 100px;
  }

  .user__info {
    width: 100%;
    display: flex !important;
    justify-content: space-between !important;
    float: none;
  }

  h2 {
    display: none;
  }
}
</style>
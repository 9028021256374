import Swal from 'sweetalert2'
import { mapState } from 'vuex'
import axios from 'axios'
import { auth } from '../config/firebase'

export default {
    data() {
        return {
            route: ""
        }
    },
    computed: {
        ...mapState({
            currentUser: (state) => state.user.currentUser
        })
    },
    methods: {
        buy(type, id, title) {
            console.log(title);
            const abbrs = {
                course: 'Complete course',
                module: 'Lesson',
                topic: 'Video'
            }
            if (this.currentUser === "") {
                Swal.fire({
                    icon: "info",
                    title: "Please login!",
                    confirmButtonColor: "#00c5b4",
                    text: 'Please Login to make any purchase',
                });
                return
            }

            Swal.fire({
                title: `Purchase ${abbrs[type]}?`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#00c5b4",
                cancelButtonColor: "#d33",
                confirmButtonText: "Purchase",
            }).then(async (result) => {
                if (result.value) {
                    this.$store.dispatch("setLoader", true);

                    const data = {}

                    if (type === 'course') {
                        data.courseId = id
                        data.userId = this.currentUser.docId;
                        data.amount = ""
                        data.orderId = ""
                        data.createdAt = new Date()

                        this.route = 'course'
                    }

                    if (type === 'module') {
                        data.moduleId = id
                        data.userId = this.currentUser.docId;
                        data.amount = ""
                        data.orderId = ""
                        data.createdAt = new Date()

                        this.route = 'module'
                    }

                    if (type === 'topic') {
                        data.topicId = id
                        data.userId = this.currentUser.docId;
                        data.amount = ""
                        data.orderId = ""
                        data.createdAt = new Date()

                        this.route = 'topic'
                    }

                    const response = await axios.post(
                        `${this.$API_BASE_URL}/purchase/${this.route}/${id}`, data,
                        {
                            headers: {
                                idToken: await auth.currentUser.getIdToken()
                            }
                        }
                    );
                    console.log(response);
                    if (response.status === 400) {
                        Swal.fire({
                            icon: "error",
                            confirmButtonColor: "#00c5b4",
                            text: response.data.message,
                        });
                        this.$store.dispatch("setLoader", false);
                        return
                    }
                    Swal.fire({
                        icon: "success",
                        title: "Purchased",
                        confirmButtonColor: "#00c5b4",
                        text: 'Purchase Successful',
                    });
                    this.currentUser.purchases = response.data.data
                    this.$store.dispatch('setCurrentUser', this.currentUser)
                    this.$store.dispatch("setLoader", false);
                    setTimeout(() => {
                        window.location.reload()
                    }, 2000)
                }
            })
        }
    }
}
import { auth, usersRef } from "../config/firebase"
import store from '../store'

export const Login = (credentials) => {
    return new Promise((resolve) => {
        auth.signInWithEmailAndPassword(credentials.email, credentials.password)
            .then(async res => {
                if (!res.user.emailVerified) {
                    resolve({ status: 400, message: "Please verify your email before login!" })
                    return
                }
                const docRef = await usersRef.doc(res.user.uid).get();
                resolve({ status: 200, message: 'success', data: docRef.data() })
            })
            .catch(error => {
                resolve({ status: 400, message: error.message })
            })
    })
}

export const Register = (data) => {
    return new Promise(resolve => {
        auth.createUserWithEmailAndPassword(data.email, data.password).then(async authRef => {
            auth.currentUser.sendEmailVerification().then(async () => {
                data.docId = authRef.user.uid
                await usersRef.doc(authRef.user.uid).set(data).then(() => {
                    resolve({ status: 200, message: 'success' })
                }).catch(error => {
                    resolve({ status: 400, message: error.message })
                })
            }).catch(error => {
                resolve({ status: 400, message: error.message })
            })
        }).catch(error => {
            resolve({ status: 400, message: error.message })
        })
    })
}

export const Logout = () => {
    return new Promise(resolve => {
        try {
            auth.signOut()
            store.dispatch('setCurrentUser', "")
            resolve({ status: 200, message: 'success' })
        } catch (error) {
            resolve({ status: 400, message: error.message })
        }
    })
}

async function checkIfPhoneNumberExists(phoneNumber) {
    const document = await usersRef.where('phoneNumber', '==', phoneNumber).get()
    return document.size === 0 ? false : true
}

auth.languageCode = "en"

export const sendOTPToPhoneNumber = (phoneNumber) => {
    return new Promise(resolve => {
        checkIfPhoneNumberExists(phoneNumber.substring(3, phoneNumber.length)).then(res => {
            if (res) {
                const appVerifier = window.recaptchaVerifier;
                auth.signInWithPhoneNumber(phoneNumber, appVerifier)
                    .then((confirmationResult) => {
                        // SMS sent. Prompt user to type the code from the message, then sign the
                        // user in with confirmationResult.confirm(code).
                        window.confirmationResult = confirmationResult;
                        resolve({ status: 200, message: 'success', data: confirmationResult })
                    }).catch((error) => {
                        // Error; SMS not sent
                        window.recaptchaVerifier.render().then(function (widgetId) {
                            window.recaptchaVerifier.reset(widgetId);
                        })
                        resolve({ status: 400, message: error.message })
                    });
            } else {
                resolve({ status: 400, message: "There is no account associated with this Mobile number. Please signup to create one." })
            }
        })
    })
}

export const verifyOTP = (otp) => {
    return new Promise(resolve => {
        window.confirmationResult.confirm(otp).then((result) => {
            // User signed in successfully.
            const user = result.user;
            resolve({ status: 200, message: 'success', data: user })
        }).catch((error) => {
            // User couldn't sign in (bad verification code?)
            resolve({ status: 400, message: error.message })
        });
    })
}

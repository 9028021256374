<template>
  <v-dialog persistent max-width="500" v-model="show">
    <v-card>
      <v-card-title>My Profile</v-card-title>
      <v-form @submit.prevent="update()" ref="profileForm">
        <v-card-text class="mt-5">
          <v-text-field
            label="Name"
            outlined
            dense
            v-model="currentUser.displayName"
            :rules="rules.displayName"
          ></v-text-field>
          <v-text-field
            label="Email"
            outlined
            dense
            disabled
            v-model="currentUser.email"
            :rules="rules.email"
          ></v-text-field>
          <v-text-field
            label="Mobile number"
            outlined
            dense
            v-model="currentUser.phoneNumber"
            :rules="rules.phoneNumber"
            @keypress="readOnlyNumbers"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" type="submit">Update</v-btn>
          <v-btn color="red" text @click="show = false">Close</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import { auth } from "../../config/firebase";
import _helpers from "../../mixins/_helpers";
import axios from "axios";

export default {
  name: "Profile",
  props: ["visible"],
  mixins: [_helpers],
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) return this.$emit("close");
      },
    },
    ...mapState({
      currentUser: (state) => state.user.currentUser,
    }),
  },
  methods: {
    async update() {
      if (this.$refs.profileForm.validate()) {
        this.$store.dispatch("setLoader", true);
        try {
          const idToken = await auth.currentUser.getIdToken();
          await axios.patch(
            `${this.$API_BASE_URL}/user/${this.currentUser.docId}`,
            {
              idToken: idToken,
              data: {
                displayName: this.currentUser.displayName,
                phoneNumber: this.currentUser.phoneNumber,
              },
            }
          );
          this.$toast.success("Profile updated", {
            position: "top",
          });
          this.show = false;
          this.$store.dispatch("setLoader", false);
        } catch (error) {
          this.$toast.error(error, {
            position: "top",
          });
          this.$store.dispatch("setLoader", false);
        }
      }
    },
  },
};
</script>

<style scoped>
</style>
<template>
  <v-container>
    <div>
      <p class="c15">
        <span class="c8"
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
        >
      </p>
      <p class="c14"><span class="c12"></span></p>
    </div>
    <p class="c11"><span class="c5">Privacy Policy</span></p>
    <p class="c2"><span class="c1"></span></p>
    <p class="c7">
      <span class="c13">At www.gyanathon.com (the &ldquo;</span
      ><span class="c6">Site</span
      ><span class="c13"
        >&rdquo;), a website owned by Gyanathon Learning Solutions Private
        Limited (&ldquo;</span
      ><span class="c6">GLSPL</span
      ><span class="c1"
        >&rdquo;), we are committed to safeguarding your privacy online. We want
        to assure our users that GLSPL will not willfully disclose any specific
        personal information about you to any third party unless you give us
        your express permission to do so.&nbsp;</span
      >
    </p>
    <p class="c2"><span class="c1"></span></p>
    <p class="c7">
      <span class="c13">Please read this Privacy Policy (&ldquo;</span
      ><span class="c6">Policy</span
      ><span class="c1"
        >&rdquo;) for information on how we treat your personal information that
        is disclosed to us. By using the Site, you consent to the collection and
        use of personal information as outlined in this Policy. If you do not
        agree to this Policy, please refrain from using the Site. GLSPL reserves
        the right to carry out modifications to this Policy from time to time as
        it deems fit, without prior notice. Any changes made to the Policy will
        be posted here to keep you informed of how personal information is
        collected, how that information is used, and under what circumstances
        that information may be disclosed. Since this Policy may change from
        time to time, you should check back periodically. Your continued use of
        the Site following the posting of changes to the Policy will imply your
        acceptance of such revisions to the Policy.&nbsp;</span
      >
    </p>
    <p class="c2"><span class="c1"></span></p>
    <p class="c7">
      <span class="c13"
        >Unless GLSPL provides a link to a different policy or states otherwise,
        this Policy applies when you visit or use the Site, mobile applications,
        APIs or related services (the &ldquo;</span
      ><span class="c6">Services</span><span class="c1">&rdquo;).</span>
    </p>
    <p class="c2"><span class="c1"></span></p>
    <p class="c2"><span class="c1"></span></p>
    <hr style="page-break-before: always; display: none" />
    <p class="c9"><span class="c5"></span></p>
    <p class="c11"><span class="c6">TABLE OF CONTENTS</span></p>
    <p class="c7"><span class="c6">&nbsp;</span></p>
    <ol class="c4 lst-kix_list_1-0 start" start="1">
      <li class="c7 c10 li-bullet-0">
        <span class="c1"
          >What personally identifiable information of yours or third party
          personally identifiable information is collected from you through the
          Website.</span
        >
      </li>
    </ol>
    <p class="c2 c3"><span class="c1"></span></p>
    <ol class="c4 lst-kix_list_1-0" start="2">
      <li class="c7 c10 li-bullet-0">
        <span class="c1">The organisation collecting the information.</span>
      </li>
    </ol>
    <p class="c2"><span class="c1"></span></p>
    <ol class="c4 lst-kix_list_1-0" start="3">
      <li class="c7 c10 li-bullet-0">
        <span class="c1">How the information is used.</span>
      </li>
    </ol>
    <p class="c2"><span class="c1"></span></p>
    <ol class="c4 lst-kix_list_1-0" start="4">
      <li class="c7 c10 li-bullet-0">
        <span class="c1">With whom the information may be shared.</span>
      </li>
    </ol>
    <p class="c2"><span class="c1"></span></p>
    <ol class="c4 lst-kix_list_1-0" start="5">
      <li class="c7 c10 li-bullet-0">
        <span class="c1"
          >What choices are available to you regarding collection, use and
          distribution of the information.</span
        >
      </li>
    </ol>
    <p class="c2"><span class="c1"></span></p>
    <ol class="c4 lst-kix_list_1-0" start="6">
      <li class="c7 c10 li-bullet-0">
        <span class="c1"
          >The kind of security procedures that are in place to protect the
          loss, misuse or alteration of information under our control.</span
        >
      </li>
    </ol>
    <p class="c3 c17"><span class="c1"></span></p>
    <ol class="c4 lst-kix_list_1-0" start="7">
      <li class="c7 c10 li-bullet-0">
        <span class="c1">Grievance redressal mechanism. </span>
      </li>
    </ol>
    <p class="c2"><span class="c1"></span></p>
    <hr style="page-break-before: always; display: none" />
    <p class="c9"><span class="c5"></span></p>
    <ol class="c4 lst-kix_list_2-0 start" start="1">
      <li class="c7 c10 li-bullet-0">
        <span class="c5"
          >What personally identifiable information of yours or third party
          personally identifiable information is collected from you through the
          Site</span
        >
      </li>
    </ol>
    <p class="c0"><span class="c1">&nbsp;</span></p>
    <p class="c0">
      <span class="c1"
        >GLSPL does not collect personally identifiable information through the
        Site unless you provide that information during registration for various
        features on the Site (e.g., personalisation, newsletters, purchases on
        the Site etc.). Information required to participate in such features may
        vary but will typically include your first and last name; address of
        residence including country, city, state, pin code; complete date of
        birth; phone number and e-mail address; and, in the case of making
        purchases, a credit/debit card number and expiration date, bank details
        or any other payment mechanism. You may also be requested to provide a
        username and password for certain features. GLSPL will maintain all of
        the personal information submitted by you in secured servers behind
        corporate firewalls.</span
      >
    </p>
    <p class="c2 c3"><span class="c1"></span></p>
    <ol class="c4 lst-kix_list_2-0" start="2">
      <li class="c7 c10 li-bullet-0">
        <span class="c5">The organization collecting the information</span>
      </li>
    </ol>
    <p class="c2 c3"><span class="c1"></span></p>
    <p class="c0">
      <span class="c1"
        >The Site is operated by GLSPL. GLSPL can be contacted at
        info@gyanathon.com&nbsp;for more information.
      </span>
    </p>
    <p class="c2 c3"><span class="c1"></span></p>
    <ol class="c4 lst-kix_list_2-0" start="3">
      <li class="c7 c10 li-bullet-0">
        <span class="c5">How the information is used</span
        ><span class="c1">&nbsp;</span>
      </li>
    </ol>
    <p class="c2 c3"><span class="c1"></span></p>
    <p class="c0">
      <span class="c1"
        >GLSPL may use information provided at registration or information
        obtained through third parties to offer better targeted products and
        services to you. We use targeting to customise and enhance your online
        experience on the Site. The use of information provided at registration
        or obtained through third parties will not be provided to advertisers or
        partners, except in aggregate form, unless you have consented to such
        use at registration.&nbsp;</span
      >
    </p>
    <p class="c2 c3"><span class="c1"></span></p>
    <p class="c0">
      <span class="c1"
        >The Site uses &quot;cookie&quot; technology. Cookies are pieces of
        information transferred to your hard drive for record-keeping purposes.
        Such technology is an industry standard and is used by most major
        websites. The Site will never use cookies to retrieve information from
        your computer that was not originally sent in a cookie. In order to
        effectively use the Site and some of its features, your browser must
        accept cookies.&nbsp;
      </span>
    </p>
    <p class="c2 c3"><span class="c1"></span></p>
    <p class="c0">
      <span class="c1"
        >Users should be aware that GLSPL cannot control the use of cookies or
        the resulting information by advertisers or third parties hosting data
        for GLSPL. If a user does not want information collected through the use
        of cookies, there is a simple procedure in most browsers that allows the
        user to deny or accept cookies; however, users should note that cookies
        may be necessary to provide the user with certain features available on
        the Site.&nbsp;</span
      >
    </p>
    <p class="c2 c3"><span class="c1"></span></p>
    <p class="c0">
      <span class="c1"
        >The Site tracks certain general usage pattern information, including
        what pages people visit and how long they have been registered users.
        The Site may log users&#39; IP addresses (the group of four numbers
        separated by full stops, such as 192.168.1.10 , that identifies a
        particular host computer) for the purposes of system administration,
        site tracking and reporting of aggregate usage information to our
        marketing and product development teams.&nbsp;</span
      >
    </p>
    <p class="c2 c3"><span class="c1"></span></p>
    <p class="c0">
      <span class="c1"
        >From time to time, GLSPL may solicit your opinion to determine your
        preferences and dislikes in order to provide you with a better product.
        These surveys are always optional to the user and your decision not to
        participate in a survey will not affect your status as user. Should you
        choose to participate, any information you give us may be used in
        aggregate form for marketing purposes, and to enhance your overall
        experience on our Site.&nbsp;</span
      >
    </p>
    <p class="c2 c3"><span class="c1"></span></p>
    <ol class="c4 lst-kix_list_2-0" start="4">
      <li class="c7 c10 li-bullet-0">
        <span class="c5">With whom the information may be shared&nbsp;</span>
      </li>
    </ol>
    <p class="c2 c3"><span class="c1"></span></p>
    <p class="c0">
      <span class="c1"
        >Other than to its affiliates, GLSPL will not provide any personally
        identifiable information to advertisers or other partners without your
        consent. As stated above, GLSPL may, however, provide advertisers and/or
        other partners with anonymous aggregated information about our users to
        illustrate trends and patterns. All third parties with whom we share
        such information will have independent privacy and data collection
        practices and GLSPL has no responsibility or liability for their
        policies or actions.</span
      >
    </p>
    <p class="c2 c3"><span class="c1"></span></p>
    <p class="c0">
      <span class="c1"
        >We will never give out any information linking your real name to your
        screen name unless you authorise us to do so, or unless required to do
        so by law.&nbsp;</span
      >
    </p>
    <p class="c2 c3"><span class="c1"></span></p>
    <p class="c0">
      <span class="c1"
        >GLSPL has several partners that can offer valuable products and
        services to our members. From time to time, GLSPL may give or sell a
        list from our database of users to these third party vendors. These
        lists will consist of only those users that have &ldquo;opted in&rdquo;
        to receiving special offers and promotions during the initial
        registration process.
      </span>
    </p>
    <p class="c2 c3"><span class="c1"></span></p>
    <p class="c0">
      <span class="c1"
        >GLSPL will use commercially reasonable efforts to ensure that every
        marketing offer sent to a user as a result of the provision of such
        information contains a link for that user to no longer receive such
        offers. In addition, we may share your information with a company that
        acquires all or substantially all of GLSPL&rsquo;s assets or in a merger
        or acquisition.
      </span>
    </p>
    <p class="c2 c3"><span class="c1"></span></p>
    <p class="c0">
      <span class="c1"
        >While GLSPL is careful in choosing its partners and advertisers, we are
        only responsible for content on the Site. All third parties associated
        with GLSPL (including, but not limited to, linked websites, advertisers,
        sponsors and other internet domains) with whom we share information have
        independent privacy and data collection practices. GLSPL does not
        control this collection of information and is neither responsible nor
        liable for their independent policies or actions.</span
      >
    </p>
    <p class="c2 c3"><span class="c1"></span></p>
    <ol class="c4 lst-kix_list_2-0" start="5">
      <li class="c7 c10 li-bullet-0">
        <span class="c5"
          >What choices are available to you regarding collection, use and
          distribution of the information</span
        ><span class="c1">&nbsp;</span>
      </li>
    </ol>
    <p class="c2 c3"><span class="c1"></span></p>
    <p class="c0">
      <span class="c1"
        >You are not required to receive any mailings that you do not wish to
        receive. During the registration process, you will have the option to
        sign up to receive newsletters and selected sponsor offers and
        discounts. You will not receive such information if you do not select
        such options. If, after you choose to receive such information, you wish
        to discontinue receiving these items, you may change your subscription
        preferences by following the instructions provided within the e-mails
        received.</span
      >
    </p>
    <p class="c2 c3"><span class="c1"></span></p>
    <ol class="c4 lst-kix_list_2-0" start="6">
      <li class="c7 c10 li-bullet-0">
        <span class="c5"
          >The kind of security procedures that are in place to protect the
          loss, misuse or alteration of information under our control</span
        ><span class="c1">&nbsp;</span>
      </li>
    </ol>
    <p class="c2"><span class="c1"></span></p>
    <p class="c0">
      <span class="c1"
        >GLSPL has security measures in place to protect against the loss,
        misuse and alteration of the information under its control. Beyond the
        physical protection of the servers, we protect against data loss by
        regularly backing up data, performing consistency checks with
        independently recorded log files to prevent tampering, and restricting
        access to information on our servers via several user authentication
        technologies. The Site uses SSL or &quot;Secure Sockets Layer&quot;, an
        industry standard security protocol. When you place an order on the Site
        and your credit/debit card information, bank details or details of any
        other payment details are transmitted, you will connect with our secure
        server. SSL sends your browser information that encrypts your order,
        changing all the information that you send back into a code that is
        extremely difficult to decipher.&nbsp;</span
      >
    </p>
    <p class="c2 c3"><span class="c1"></span></p>
    <p class="c0">
      <span class="c1"
        >Only those employees needed to carry out the business functions will
        have access to information on individual customers. We train each such
        employee with respect to the Policy, and those violating these policies
        will be subject to disciplinary action.&nbsp;</span
      >
    </p>
    <p class="c2 c3"><span class="c1"></span></p>
    <p class="c0">
      <span class="c1"
        >It is important to remember that whatever you transmit or disclose
        online can be collected and used by others or unlawfully intercepted by
        third parties. No data transmission over the Internet can be guaranteed
        to be one hundred percent (100%) secure. While we strive to protect your
        personal information, we cannot warrant the security of any information
        you transmit to us. In addition, we may disclose information to law
        enforcement or other government officials if we determine such
        disclosure to be appropriate, or if we are compelled to disclose it by
        court order or operation of law.</span
      >
    </p>
    <p class="c0"><span class="c1">&nbsp;</span></p>
    <p class="c0">
      <span class="c1"
        >Any information voluntarily disclosed by users and posted on our Site
        &nbsp;may be viewed and used by others. If users post personal
        information that is accessible to the public online, the user does so at
        his or her own risk. Users accept that they may receive unsolicited
        messages from other parties in return.&nbsp;</span
      >
    </p>
    <p class="c2 c3"><span class="c1"></span></p>
    <ol class="c4 lst-kix_list_2-0" start="7">
      <li class="c7 c10 li-bullet-0">
        <span class="c5">Grievance redressal mechanism.</span>
      </li>
    </ol>
    <p class="c2"><span class="c1"></span></p>
    <p class="c0">
      <span class="c1"
        >In the event you have any concerns regarding the use and disclosure of
        your personal information provided to GLSPL through the Site, you may
        raise such concerns with Mrs. Vijayalaxmi Pai, GLSPL&rsquo;s grievance
        officer at info@gyanathon.com. Please note that any complaints or
        queries submitted to the grievance officer shall be addressed within a
        period of one (1) month from receiving such query or complaint as the
        case may be.</span
      >
    </p>
    <p class="c2 c3"><span class="c1"></span></p>
    <p class="c7"><span class="c1">&nbsp;</span></p>
    <p class="c9" id="h.gjdgxs"><span class="c1"></span></p>
    <div>
      <p class="c14"><span class="c12"></span></p>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "PrivacyPolicy",
};
</script>

<style scoped>
ol.lst-kix_list_1-3 {
  list-style-type: none;
}

ol.lst-kix_list_1-4 {
  list-style-type: none;
}

.lst-kix_list_2-6 > li:before {
  content: "" counter(lst-ctn-kix_list_2-6, decimal) ". ";
}

.lst-kix_list_2-7 > li:before {
  content: "" counter(lst-ctn-kix_list_2-7, lower-latin) ". ";
}

.lst-kix_list_2-7 > li {
  counter-increment: lst-ctn-kix_list_2-7;
}

ol.lst-kix_list_1-5 {
  list-style-type: none;
}

ol.lst-kix_list_1-6 {
  list-style-type: none;
}

.lst-kix_list_2-1 > li {
  counter-increment: lst-ctn-kix_list_2-1;
}

ol.lst-kix_list_1-0 {
  list-style-type: none;
}

.lst-kix_list_2-4 > li:before {
  content: "" counter(lst-ctn-kix_list_2-4, lower-latin) ". ";
}

.lst-kix_list_2-5 > li:before {
  content: "" counter(lst-ctn-kix_list_2-5, lower-roman) ". ";
}

.lst-kix_list_2-8 > li:before {
  content: "" counter(lst-ctn-kix_list_2-8, lower-roman) ". ";
}

ol.lst-kix_list_1-1 {
  list-style-type: none;
}

ol.lst-kix_list_1-2 {
  list-style-type: none;
}

.lst-kix_list_1-1 > li {
  counter-increment: lst-ctn-kix_list_1-1;
}

ol.lst-kix_list_2-6.start {
  counter-reset: lst-ctn-kix_list_2-6 0;
}

ol.lst-kix_list_1-8.start {
  counter-reset: lst-ctn-kix_list_1-8 0;
}

ol.lst-kix_list_2-3.start {
  counter-reset: lst-ctn-kix_list_2-3 0;
}

ol.lst-kix_list_1-5.start {
  counter-reset: lst-ctn-kix_list_1-5 0;
}

ol.lst-kix_list_1-7 {
  list-style-type: none;
}

.lst-kix_list_1-7 > li {
  counter-increment: lst-ctn-kix_list_1-7;
}

ol.lst-kix_list_1-8 {
  list-style-type: none;
}

ol.lst-kix_list_2-5.start {
  counter-reset: lst-ctn-kix_list_2-5 0;
}

.lst-kix_list_2-0 > li {
  counter-increment: lst-ctn-kix_list_2-0;
}

.lst-kix_list_2-3 > li {
  counter-increment: lst-ctn-kix_list_2-3;
}

.lst-kix_list_2-6 > li {
  counter-increment: lst-ctn-kix_list_2-6;
}

ol.lst-kix_list_1-7.start {
  counter-reset: lst-ctn-kix_list_1-7 0;
}

.lst-kix_list_1-2 > li {
  counter-increment: lst-ctn-kix_list_1-2;
}

ol.lst-kix_list_2-2.start {
  counter-reset: lst-ctn-kix_list_2-2 0;
}

.lst-kix_list_1-5 > li {
  counter-increment: lst-ctn-kix_list_1-5;
}

.lst-kix_list_1-8 > li {
  counter-increment: lst-ctn-kix_list_1-8;
}

ol.lst-kix_list_1-4.start {
  counter-reset: lst-ctn-kix_list_1-4 0;
}

ol.lst-kix_list_1-1.start {
  counter-reset: lst-ctn-kix_list_1-1 0;
}

ol.lst-kix_list_2-2 {
  list-style-type: none;
}

ol.lst-kix_list_2-3 {
  list-style-type: none;
}

ol.lst-kix_list_2-4 {
  list-style-type: none;
}

ol.lst-kix_list_2-5 {
  list-style-type: none;
}

.lst-kix_list_1-4 > li {
  counter-increment: lst-ctn-kix_list_1-4;
}

ol.lst-kix_list_2-0 {
  list-style-type: none;
}

.lst-kix_list_2-4 > li {
  counter-increment: lst-ctn-kix_list_2-4;
}

ol.lst-kix_list_1-6.start {
  counter-reset: lst-ctn-kix_list_1-6 0;
}

ol.lst-kix_list_2-1 {
  list-style-type: none;
}

ol.lst-kix_list_1-3.start {
  counter-reset: lst-ctn-kix_list_1-3 0;
}

ol.lst-kix_list_2-8.start {
  counter-reset: lst-ctn-kix_list_2-8 0;
}

ol.lst-kix_list_1-2.start {
  counter-reset: lst-ctn-kix_list_1-2 0;
}

.lst-kix_list_1-0 > li:before {
  content: "" counter(lst-ctn-kix_list_1-0, decimal) ". ";
}

ol.lst-kix_list_2-6 {
  list-style-type: none;
}

.lst-kix_list_1-1 > li:before {
  content: "" counter(lst-ctn-kix_list_1-1, lower-latin) ". ";
}

.lst-kix_list_1-2 > li:before {
  content: "" counter(lst-ctn-kix_list_1-2, lower-roman) ". ";
}

ol.lst-kix_list_2-0.start {
  counter-reset: lst-ctn-kix_list_2-0 0;
}

ol.lst-kix_list_2-7 {
  list-style-type: none;
}

ol.lst-kix_list_2-8 {
  list-style-type: none;
}

.lst-kix_list_1-3 > li:before {
  content: "" counter(lst-ctn-kix_list_1-3, decimal) ". ";
}

.lst-kix_list_1-4 > li:before {
  content: "" counter(lst-ctn-kix_list_1-4, lower-latin) ". ";
}

ol.lst-kix_list_1-0.start {
  counter-reset: lst-ctn-kix_list_1-0 0;
}

.lst-kix_list_1-0 > li {
  counter-increment: lst-ctn-kix_list_1-0;
}

.lst-kix_list_1-6 > li {
  counter-increment: lst-ctn-kix_list_1-6;
}

.lst-kix_list_1-7 > li:before {
  content: "" counter(lst-ctn-kix_list_1-7, lower-latin) ". ";
}

ol.lst-kix_list_2-7.start {
  counter-reset: lst-ctn-kix_list_2-7 0;
}

.lst-kix_list_1-3 > li {
  counter-increment: lst-ctn-kix_list_1-3;
}

.lst-kix_list_1-5 > li:before {
  content: "" counter(lst-ctn-kix_list_1-5, lower-roman) ". ";
}

.lst-kix_list_1-6 > li:before {
  content: "" counter(lst-ctn-kix_list_1-6, decimal) ". ";
}

li.li-bullet-0:before {
  margin-left: -36pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 36pt;
}

.lst-kix_list_2-0 > li:before {
  content: "" counter(lst-ctn-kix_list_2-0, decimal) ". ";
}

.lst-kix_list_2-1 > li:before {
  content: "" counter(lst-ctn-kix_list_2-1, lower-latin) ". ";
}

ol.lst-kix_list_2-1.start {
  counter-reset: lst-ctn-kix_list_2-1 0;
}

.lst-kix_list_2-5 > li {
  counter-increment: lst-ctn-kix_list_2-5;
}

.lst-kix_list_2-8 > li {
  counter-increment: lst-ctn-kix_list_2-8;
}

.lst-kix_list_1-8 > li:before {
  content: "" counter(lst-ctn-kix_list_1-8, lower-roman) ". ";
}

.lst-kix_list_2-2 > li:before {
  content: "" counter(lst-ctn-kix_list_2-2, lower-roman) ". ";
}

.lst-kix_list_2-3 > li:before {
  content: "" counter(lst-ctn-kix_list_2-3, decimal) ". ";
}

.lst-kix_list_2-2 > li {
  counter-increment: lst-ctn-kix_list_2-2;
}

ol.lst-kix_list_2-4.start {
  counter-reset: lst-ctn-kix_list_2-4 0;
}

ol {
  margin: 0;
  padding: 0;
}

table td,
table th {
  padding: 0;
}

.c5 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: "Arial";
  font-style: normal;
}

.c2 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 2;
  orphans: 2;
  widows: 2;
  text-align: justify;
  height: 11pt;
}

.c0 {
  margin-left: 36pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 2;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c14 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 2;
  orphans: 2;
  widows: 2;
  text-align: left;
  height: 11pt;
}

.c8 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: "Garamond";
  font-style: italic;
}

.c17 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 2;
  orphans: 2;
  widows: 2;
  text-align: left;
  height: 11pt;
}

.c12 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12pt;
  font-family: "Garamond";
  font-style: italic;
}

.c9 {
  padding-top: 0pt;
  padding-bottom: 10pt;
  line-height: 2;
  orphans: 2;
  widows: 2;
  text-align: left;
  height: 11pt;
}

.c1 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: "Arial";
  font-style: normal;
}

.c11 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 2;
  orphans: 2;
  widows: 2;
  text-align: center;
}

.c7 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 2;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c15 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 2;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.c6 {
  font-family: "Arial";
  color: #000000;
  font-weight: 700;
}

.c13 {
  font-family: "Arial";
  color: #000000;
  font-weight: 400;
}

.c16 {
  background-color: #ffffff;
  max-width: 468pt;
  padding: 72pt 72pt 72pt 72pt;
}

.c4 {
  padding: 0;
  margin: 0;
}

.c10 {
  margin-left: 18pt;
  padding-left: 18pt;
}

.c3 {
  margin-left: 36pt;
}

.title {
  padding-top: 24pt;
  color: #000000;
  font-weight: 700;
  font-size: 36pt;
  padding-bottom: 6pt;
  font-family: "Calibri";
  line-height: 2;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.subtitle {
  padding-top: 18pt;
  color: #666666;
  font-size: 24pt;
  padding-bottom: 4pt;
  font-family: "Georgia";
  line-height: 2;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}

li {
  color: #000000;
  font-size: 11pt;
  font-family: "Calibri";
}

p {
  margin: 0;
  color: #000000;
  font-size: 11pt;
  font-family: "Calibri";
}

h1 {
  padding-top: 24pt;
  color: #000000;
  font-weight: 700;
  font-size: 24pt;
  padding-bottom: 6pt;
  font-family: "Calibri";
  line-height: 2;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h2 {
  padding-top: 18pt;
  color: #000000;
  font-weight: 700;
  font-size: 18pt;
  padding-bottom: 4pt;
  font-family: "Calibri";
  line-height: 2;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h3 {
  padding-top: 14pt;
  color: #000000;
  font-weight: 700;
  font-size: 14pt;
  padding-bottom: 4pt;
  font-family: "Calibri";
  line-height: 2;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h4 {
  padding-top: 12pt;
  color: #000000;
  font-weight: 700;
  font-size: 12pt;
  padding-bottom: 2pt;
  font-family: "Calibri";
  line-height: 2;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h5 {
  padding-top: 11pt;
  color: #000000;
  font-weight: 700;
  font-size: 11pt;
  padding-bottom: 2pt;
  font-family: "Calibri";
  line-height: 2;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h6 {
  padding-top: 10pt;
  color: #000000;
  font-weight: 700;
  font-size: 10pt;
  padding-bottom: 2pt;
  font-family: "Calibri";
  line-height: 2;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('Header'),(
      _vm.$store.state.user.currentUser === '' ||
      (_vm.$store.state.user.currentUser !== '' &&
        _vm.$store.state.user.currentUser.purchases.length === 0)
    )?_c('div',{staticClass:"buy__course"},[_c('h2',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"black","link":"","to":"/courses"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-arrow-left")])],1)]}}],null,false,3461833916)},[_c('span',[_vm._v("Back to modules")])]),_vm._v(" Buy Complete Course ")],1),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.buy('course', _vm.$route.params.id)}}},[_vm._v("Buy Now")])],1):_vm._e(),_c('v-row',{staticClass:"mt-5"},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[(_vm.lessons.lessons !== undefined)?_c('div',{staticClass:"player__section"},[(!_vm.lessons.lessons[0].locked && _vm.currentUser !== '')?_c('video',{attrs:{"controls":"","controlsList":"nodownload","src":_vm.lessons.lessons[0].videoURL,"autoplay":""},on:{"ended":function($event){return _vm.handleVideoCount(_vm.lessons.lessons[0])},"contextmenu":function($event){$event.preventDefault();}}}):_c('div',{staticClass:"videoThumbnail"},[_c('img',{attrs:{"src":_vm.lessons.lessons[0].bannerImg,"alt":"Gyanathon"}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [(
                  _vm.currentUser !== '' &&
                  _vm.currentUser.purchases.length !== 0 &&
                  !_vm.lessons.lessons[0].extended
                )?_c('v-btn',_vm._g(_vm._b({staticClass:"float-right locked__on_screen",attrs:{"color":"primary"},on:{"click":function($event){return _vm.buy(
                    'topic',
                    _vm.lessons.lessons[0].id,
                    _vm.lessons.lessons[0].title
                  )}}},'v-btn',attrs,false),on),[_vm._v("Purchase"),_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-lock")])],1):_vm._e()]}}],null,false,2666101679)},[_c('span',[_vm._v("You have exceeded with the maximum 4 viewing quota. You can buy 2 more quota.")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [(
                  _vm.currentUser !== '' &&
                  _vm.currentUser.purchases.length !== 0 &&
                  _vm.lessons.lessons[0].extendedCount == 2
                )?_c('v-btn',_vm._g(_vm._b({staticClass:"float-right locked__on_screen",attrs:{"icon":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-lock")])],1):_vm._e()]}}],null,false,1963879404)},[_c('span',[_vm._v("You have exhausted with the maximum 4 viewing quota and 2 bonus quota. You can't view this video again.")])])],1),_c('h2',[_vm._v(_vm._s(_vm.lessons.lessons[0].title))]),_c('p',[_vm._v(" "+_vm._s(_vm.lessons.lessons[0].description)+" ")])]):_vm._e()]),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('h2',[_vm._v("Upcomings")]),_c('div',{staticClass:"upcomings mt-3"},[(!_vm.loaded)?_c('list-skeleton'):_vm._e(),_vm._l((_vm.lessons.lessons),function(lesson,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(index !== 0),expression:"index !== 0"}],key:index,staticClass:"module__row",on:{"click":function($event){return _vm.view(lesson.id)}}},[_c('div',{staticClass:"thumbnail"},[_c('img',{attrs:{"src":lesson.bannerImg,"alt":lesson.title}})]),_c('div',{staticClass:"module__details px-2"},[_c('h3',[_vm._v(" "+_vm._s(lesson.title)+" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [(lesson.locked)?_c('v-btn',_vm._g(_vm._b({staticClass:"float-right",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-lock")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Purchase to unlock")])])],1),_c('p',[_vm._v(_vm._s(_vm.truncateParagraph(50, lesson.description)))]),(_vm.currentUser !== '' && _vm.currentUser.purchases.length !== 0)?_c('span',{staticClass:"duration"},[_vm._v("Duration: "+_vm._s(lesson.duration))]):_vm._e()])])})],2)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
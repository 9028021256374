export default {
    data() {
        return {
            rules: {
                displayName: [(v) => !!v || "Name is required"],
                email: [
                    (v) => !!v || "E-mail is required",
                    (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
                ],
                phoneNumber: [
                    (v) => !!v || "Mobile number is required",
                    (v) => /^[6-9]\d{9}$/.test(v) || "Mobile number must be valid",
                ],
                password: [
                    (v) => !!v || "Password is required",
                    (v) =>
                        (v && v.length >= 6) ||
                        "Password must be atleast 6 characters long",
                ],
                acceptRules: [
                    (v) => !!v || "Accept the Terms & conditions and Privacy policy to proceed.",
                ]
            },
        }
    },
    methods: {
        truncateParagraph(maxLength = 80, paragraph) {
            return paragraph.length <= maxLength
                ? paragraph
                : paragraph.substring(0, maxLength) + "...";
        },
        readOnlyNumbers(e) {
            return e.charCode >= 48 && e.charCode <= 57
                ? e.target.value
                : e.preventDefault();
        },
    }
}

<template>
  <v-app>
    <v-main>
      <router-view />
      <loader></loader>
    </v-main>
  </v-app>
</template>

<script>
import Loader from "./components/Modals/Loader.vue";

export default {
  components: { Loader },
  name: "App",
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap");

html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* overflow: hidden; */
  scroll-behavior: smooth;
}

#app {
  font-family: "Montserrat", sans-serif;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 1);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 1);
}

.v-btn {
  text-transform: capitalize !important;
}
</style>

export default {
    state: {
        loader: false
    },
    mutations: {
        updateLoader(state, payload) {
            state.loader = payload
        }
    },
    actions: {
        setLoader({ commit }, payload) {
            commit('updateLoader', payload)
        }
    }
}

<template>
  <div class="wrapper">
    <Header></Header>
    <div
      class="buy__course"
      v-if="
        $store.state.user.currentUser === '' ||
          ($store.state.user.currentUser !== '' &&
            $store.state.user.currentUser.purchases.length === 0)
      "
    >
      <h2>Buy Complete Course</h2>
      <form ref="myform" @click="buy('course', $route.params.id)" ></form>
    </div>
    <div class="modules__section">
      <h2>Modules Available</h2>
      <v-row>
        <v-col cols="12" md="8">
          <div class="modules__row" v-if="loaded">
            <div
              v-for="(module, index) in modules"
              :key="index"
              :class="`module__card ${module.selected ? 'selected' : ''}`"
            >
              <div class="card__header">
                <img :src="module.bannerImg" :alt="module.title" />
              </div>
              <div class="card__body">
                <h3>{{ module.title }}</h3>
                <p>{{ truncateParagraph(80, module.description) }}</p>
              </div>
              <div class="card__footer">
                <v-btn
                  color="golden"
                  class="float-right"
                  @click="view(module.docId)"
                  v-if="module.selected"
                  >View</v-btn
                >
                <v-btn
                  v-else
                  color="primary"
                  class="float-right"
                  @click="select(index)"
                  >Select</v-btn
                >
              </div>
            </div>
          </div>
          <card-skeleton class="module__card" v-else></card-skeleton>
        </v-col>
        <v-col cols="12" md="4">
          <div class="module__preview">
            <div class="preview__card">
              <img
                :src="previewData.bannerImg"
                :alt="previewData.title"
                width="100%"
                class="preview__img"
              />
              <h2>{{ previewData.title }}</h2>
              <p>
                {{ previewData.description }}
              </p>
              <div class="preview__card_footer">
                <v-btn
                  color="primary"
                  class="d-block mx-auto"
                  large
                  @click="buy('module', previewData.docId)"
                  v-if="!previewData.purchased"
                  >Buy Now</v-btn
                >
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import axios from "axios";
import _helpers from "../mixins/_helpers";
import buy from "../mixins/buy";
import { mapState } from "vuex";
import CardSkeleton from "../components/Skeletons/Card";
import { auth } from "../config/firebase";

export default {
  components: { Header, CardSkeleton },
  name: "Modules",
  mounted(){
    let foo = document.createElement("script");
    foo.setAttribute("class", "className_" + Date.now());
    foo.setAttribute(
      "src",
      "https://checkout.razorpay.com/v1/payment-button.js"
    );
    foo.setAttribute("data-payment_button_id", "pl_HAswFDeQJWPdWU");
    this.$refs.myform.appendChild(foo);
  },
  data() {
    return {
      modules: [],
      previewData: {},
      loaded: false,
    };
  },
  computed: {
    ...mapState({ currentUser: (state) => state.user.currentUser }),
  },
  mixins: [_helpers, buy],
  created() {
    this.getModules();
  },
  methods: {
    async getModules() {
      let idToken = "";
      if (this.$store.state.user.currentUser !== "") {
        idToken = await auth.currentUser.getIdToken();
      }
      const response = await axios.get(
        `${this.$API_BASE_URL}/course/${this.$route.params.id}`,
        {
          headers: {
            idToken: idToken,
          },
        }
      );
      this.modules = response.data.modules;
      this.previewData = this.modules[0];
      this.previewData.selected = true;
      this.loaded = true;
    },
    view(docId) {
      this.$router.push(`/${docId}?watch`);
    },
    select(index) {
      const activeIndex = this.modules.findIndex((module) => module.selected);
      this.modules[activeIndex].selected = false;
      this.modules[index].selected = true;
      this.previewData = this.modules[index];
    },
  },
};
</script>

<style scoped>
.wrapper {
  padding: 15px 20px;
}

.buy__course {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 80px;
  background-color: #a6d4d1;
  padding: 0 15px;
  border-radius: 10px;
}

.selected {
  background-color: #a6d4d1;
}

.modules__section {
  padding: 0 15px;
  margin-top: 30px;
}

.modules__row {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 20px;
  /* justify-content: space-between; */
}

.module__card {
  border: 1px solid #cecece;
  margin-top: 20px;
  border-radius: 5px;
  width: 30%;
  height: 350px;
  position: relative;
  margin-right: 20px;
}

.card__header {
  height: 45%;
  padding: 5px;
}

.card__header > img {
  width: 100%;
  /* height: 100%; */
  /* object-fit: cover; */
}

.card__body {
  padding: 10px 15px;
}

.card__footer {
  padding: 10px 15px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.preview__card {
  border: 1px solid #cecece;
  height: fit-content;
  margin-top: 40px;
  border-radius: 10px;
  padding: 0 15px;
  position: relative;
}

.preview__card_footer {
  bottom: 0;
  width: 100%;
  padding: 10px 0;
}

.preview__img {
  padding: 5px;
}

@media only screen and (max-width: 600px) {
  .module__card {
    width: 100%;
  }
}
</style>

export default {
    state: {
        currentUser: ""
    },
    mutations: {
        updateCurrentUser(state, payload) {
            state.currentUser = payload
        }
    },
    actions: {
        setCurrentUser({ commit }, payload) {
            commit('updateCurrentUser', payload)
        }
    }
}

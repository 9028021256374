<template>
  <div class="overlay" v-if="loader" @contextmenu.prevent>
    <img src="../../assets/images/favicon.png" alt="" class="rotate" />
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Loader",
  computed: {
    ...mapState({
      loader: (state) => state.loader.loader,
    }),
  },
};
</script>

<style scoped>
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
}

img {
  top: 40%;
  left: 45%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.rotate {
  animation: rotation 1.2s linear infinite;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>
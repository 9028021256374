<template>
  <div class="wrapper">
    <Header></Header>
    <div class="courses__section">
      <h2
        v-if="
          $store.state.user.currentUser === '' ||
          ($store.state.user.currentUser !== '' &&
            $store.state.user.currentUser.purchases.length === 0)
        "
      >
        Recommended Courses
      </h2>
      <h2 v-else>My Courses</h2>
      <div class="courses__row" v-if="loaded">
        <div
          class="course__card"
          v-for="(course, index) in courses"
          :key="index"
        >
          <div class="card__header pa-2">
            <img :src="course.bannerImg" :alt="course.title" />
          </div>
          <div class="card__body">
            <h3>{{ course.title }}</h3>
            <p>{{ truncateParagraph(90, course.description) }}</p>
          </div>
          <div class="card__footer">
            <v-chip class="ma-2" color="primary" outlined>
              <v-icon>mdi-star</v-icon>{{ course.tags[0] }}
            </v-chip>
            <v-chip class="ma-2" color="primary" outlined>
              <v-icon>mdi-cash</v-icon>Rs 200
            </v-chip>
            <v-btn color="primary" @click="start(course.docId)">{{
              btnTitle
            }}</v-btn>
          </div>
        </div>
      </div>
      <card-skeleton class="course__card" v-else></card-skeleton>
    </div>
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import axios from "axios";
import { mapState } from "vuex";
import _helpers from "../mixins/_helpers";
import CardSkeleton from "../components/Skeletons/Card";

export default {
  components: { Header, CardSkeleton },
  computed: {
    ...mapState(
      { courses: (state) => state.course.courses },
      { currentUser: (state) => state.user.currentUser }
    ),
    btnTitle: function () {
      return this.$store.state.user.currentUser === ""
        ? "Explore Modules"
        : "Start Now";
    },
  },
  name: "Courses",
  data() {
    return {
      loaded: false,
    };
  },
  mixins: [_helpers],
  created() {
    this.getCourses();
  },
  methods: {
    async getCourses() {
      if (this.$store.state.course.courses.length === 0) {
        const response = await axios.get(`${this.$API_BASE_URL}/course`);
        this.$store.dispatch("setCourses", response.data);
        this.loaded = true;
      } else this.loaded = true;
    },
    start(docId) {
      this.$router.push(`/course/${docId}`);
    },
  },
};
</script>

<style scoped>
.wrapper {
  padding: 15px 20px;
}

.courses__section {
  margin-top: 70px;
  padding: 0 15px;
}

.courses__row {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 20px;
  justify-content: space-between;
}

.course__card {
  border: 1px solid #cecece;
  margin-top: 20px;
  border-radius: 10px;
  width: 32%;
  height: 400px;
  cursor: pointer;
  position: relative;
}

.card__header > img {
  width: 100%;
}

.card__body {
  padding: 10px 15px;
}

.card__footer {
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .course__card {
    width: 100%;
  }
}
</style>
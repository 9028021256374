import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './config/firebase'
import './plugins/vue-toast-notification'

Vue.config.productionTip = true
Vue.prototype.$API_BASE_URL = (Vue.config.productionTip) ? 'https://jellyfish-app-dr879.ondigitalocean.app' : 'http://localhost:5000'

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

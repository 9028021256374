<template>
  <v-skeleton-loader
    type="list-item-avatar-three-line@5"
  ></v-skeleton-loader>
</template>

<script>
export default {
  name: "ListSkeleton",
};
</script>

<style>
</style>
<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="6">
        <img
          :src="require(`../../assets/images/${logo}`)"
          alt="Gyanathon"
          class="d-block mx-auto mb-5"
        />
        <v-form
          class="mt-5"
          ref="loginForm"
          lazy-validation
          @submit.prevent="login()"
        >
          <h1>Login</h1>
          <span>Login with your data entered during registration</span>
          <v-row class="mt-5">
            <v-col cols="12" md="12">
              <v-text-field
                label="Email Address"
                solo
                :rules="rules.email"
                v-model="credentials.email"
                type="email"
                prepend-inner-icon="mdi-email"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <v-text-field
                label="Password"
                solo
                :type="show ? 'text' : 'password'"
                @click:append="show = !show"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="rules.password"
                v-model="credentials.password"
                prepend-inner-icon="mdi-lock"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <h4>
                Don't have an account?
                <router-link to="/signup">Register here</router-link>
                <a
                  href="#"
                  class="float-right forgot"
                  @click="resetDialog = true"
                  >Forgot Password?</a
                >
              </h4>
            </v-col>
            <v-col cols="12" md="12">
              <v-btn
                color="primary"
                class="white--text d-block mt-5 mx-auto"
                large
                type="submit"
                >Login</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
        <h2 class="text-center my-5">OR</h2>
        <span>Enter Mobile Number to use OTP SignIn</span>
        <v-form ref="otpRef" @submit.prevent="sendOTP()">
          <v-row>
            <v-col cols="12" md="8">
              <v-text-field
                solo
                label="Mobile Number"
                :rules="rules.phoneNumber"
                v-model="phoneNumber"
                prefix="+91"
                @keypress="readOnlyNumbers"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-btn
                color="primary"
                class="white--text"
                large
                type="submit"
                id="send-otp-btn"
                >Send OTP</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-col>
      <v-col cols="12" md="6" class="ma-auto">
        <v-btn color="primary" link to="/courses" class="explore__btn"
          >Explore Courses</v-btn
        >
        <img
          :src="require(`../../assets/images/${homeBanner}`)"
          alt="Home Banner"
          class="d-block home__banner"
        />
      </v-col>
    </v-row>
    <OTP :visible="dialog" @close="dialog = false" :number="phoneNumber"></OTP>
    <reset-password
      :visible="resetDialog"
      @close="resetDialog = false"
    ></reset-password>
  </v-container>
</template>

<script>
import OTP from "../../components/Modals/OTP.vue";
import { logo, homeBanner } from "../../constants";
import { Login, sendOTPToPhoneNumber } from "../../services/Auth";
import firebase from "firebase";
import ResetPassword from "../../components/Modals/ResetPassword.vue";
import _helpers from "../../mixins/_helpers";

export default {
  components: { OTP, ResetPassword },
  name: "Login",
  computed: {
    logo: function () {
      return logo;
    },
    homeBanner: function () {
      return homeBanner;
    },
  },
  data() {
    return {
      credentials: {
        email: "",
        password: "",
      },
      show: false,
      dialog: false,
      resetDialog: false,
      phoneNumber: "",
    };
  },
  mixins: [_helpers],
  mounted() {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "send-otp-btn",
      {
        size: "invisible",
        callback: (response) => {
          console.log(response);
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          this.sendOTP();
        },
      }
    );
  },
  methods: {
    async login() {
      if (this.$refs.loginForm.validate()) {
        this.$store.dispatch("setLoader", true);
        const response = await Login(this.credentials);
        if (response.status === 400) {
          this.$toast.error(response.message, {
            position: "top",
          });
          this.$store.dispatch("setLoader", false);
          return;
        }
        this.$store.dispatch("setLoader", false);
        this.$store.dispatch("setCurrentUser", response.data);
        this.$router.push("/courses");
      }
    },
    async sendOTP() {
      if (this.$refs.otpRef.validate()) {
        this.$store.dispatch("setLoader", true);
        const response = await sendOTPToPhoneNumber(`+91${this.phoneNumber}`);
        if (response.status === 400) {
          this.$toast.error(response.message, {
            position: "top",
          });
          this.phoneNumber = "";
          this.$store.dispatch("setLoader", false);
          return;
        }
        this.dialog = true;
        this.$store.dispatch("setLoader", false);
      }
    },
  },
};
</script>

<style scoped>
.col-md-12 {
  padding: 5px;
}

.home__banner {
  position: absolute;
  bottom: 0;
  right: 0;
}

.explore__btn {
  position: absolute;
  top: 50px;
  right: 50px;
}

@media only screen and (max-width: 600px) {
  .explore__btn,
  .home__banner {
    display: none !important;
  }

  .v-form {
    padding: 10px;
  }

  .forgot {
    float: none !important;
    display: block;
    margin-top: 5px;
  }

  .v-btn {
    display: block;
    margin: 0 auto;
  }
}
</style>
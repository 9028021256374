export default {
    state: {
        courses: []
    },
    mutations: {
        updateCourses(state, payload) {
            state.courses = payload
        }
    },
    actions: {
        setCourses({ commit }, payload) {
            commit('updateCourses', payload)
        }
    }
}
import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Auth/Login.vue'
import SignUp from '../views/Auth/Signup.vue'
import Courses from '../views/Courses.vue'
import Modules from '../views/Modules.vue'
import Watch from '../views/Watch.vue'
import TermsAndConditions from '../views/TermsAndConditions.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
// import Launch from '../views/Launch.vue'

import store from '../store'
import { authGuard } from './authGuard'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'Launch',
  //   component: Launch
  // },
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: SignUp
  },
  {
    path: '/courses',
    name: 'Courses',
    component: Courses
  },
  {
    path: '/course/:id',
    name: 'Modules',
    component: Modules
  },
  {
    path: '/terms-and-conditions',
    name: 'TermsAndConditions',
    component: TermsAndConditions
  },
  {
    path: '/Privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  },
  {
    path: '/:id',
    name: 'Watch',
    component: Watch
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  store.dispatch('setLoader', true)
  const authState = await authGuard();
  // if (to.name !== 'Login' && !authState) next({ name: 'Courses' })
  if (to.name === 'Login' && authState) next({ name: 'Courses' })
  else next()
  store.dispatch('setLoader', false)
})

export default router

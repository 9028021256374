<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="6">
        <img
          :src="require(`../../assets/images/${logo}`)"
          alt="Gyanathon"
          class="d-block mx-auto mb-5"
        />
        <v-form
          class="mt-5"
          ref="signUpForm"
          lazy-validation
          @submit.prevent="register()"
        >
          <h1>Signup</h1>
          <span>Signup to create a new gyanathon account</span>
          <v-row class="mt-5">
            <v-col cols="12" md="12">
              <v-text-field
                label="Name"
                solo
                :rules="rules.displayName"
                v-model="data.displayName"
                prepend-inner-icon="mdi-account"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="Email Address"
                solo
                :rules="rules.email"
                v-model="data.email"
                type="email"
                prepend-inner-icon="mdi-email"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="Mobile number"
                solo
                :rules="rules.phoneNumber"
                v-model="data.phoneNumber"
                @keypress="readOnlyNumbers"
                prepend-inner-icon="mdi-phone"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <v-text-field
                label="Password"
                solo
                :type="show ? 'text' : 'password'"
                @click:append="show = !show"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="rules.password"
                v-model="data.password"
                prepend-inner-icon="mdi-lock"
              ></v-text-field>
            </v-col>
            <v-checkbox v-model="accept" :rules="rules.acceptRules">
              <template v-slot:label class="terms">
                <span class="mr-2">I agree with the</span
                ><a
                  href="/terms-and-conditions"
                  @click.stop
                  class="mr-2"
                  target="_blank"
                  >terms & conditions</a
                >
                and
                <a
                  href="/privacy-policy"
                  class="ml-2"
                  @click.stop
                  target="_blank"
                >
                  privacy policy</a
                >
              </template>
            </v-checkbox>
            <v-col cols="12" md="12">
              <h4 class="float-right">
                Already have an account?
                <router-link to="/">Login here</router-link>
              </h4>
            </v-col>
            <v-col cols="12" md="12">
              <v-btn
                color="primary"
                class="white--text d-block mt-5 mx-auto"
                large
                type="submit"
                :disabled="disabled"
                >Sign Up</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-col>
      <v-col cols="12" md="6" class="ma-auto">
        <v-btn color="primary" link to="/courses" class="explore__btn"
          >Explore Courses</v-btn
        >
        <img
          :src="require(`../../assets/images/${homeBanner}`)"
          alt="Home Banner"
          class="d-block home__banner"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { logo, homeBanner } from "../../constants";
import { Register } from "../../services/Auth";
import _helpers from "../../mixins/_helpers";

export default {
  name: "Login",
  computed: {
    logo: function () {
      return logo;
    },
    homeBanner: function () {
      return homeBanner;
    },
  },
  data() {
    return {
      data: {
        displayName: "",
        email: "",
        phoneNumber: "",
        password: "",
        purchases: [],
        purchasedModules: [],
      },
      show: false,
      dialog: false,
      disabled: false,
      accept: false,
    };
  },
  mixins: [_helpers],
  methods: {
    async register() {
      if (this.$refs.signUpForm.validate()) {
        this.disabled = true;
        this.$store.dispatch("setLoader", true);
        this.data.createdAt = new Date();
        const response = await Register(this.data);
        if (response.status === 400) {
          this.$toast.error(response.message, {
            position: "top",
          });
          this.disabled = false;
          this.$store.dispatch("setLoader", false);
          return;
        }
        this.$toast.success(
          "Registration successful. Please verify your email before sigining in",
          {
            position: "top",
          }
        );
        this.data = {
          displayName: "",
          email: "",
          phoneNumber: "",
          password: "",
        };
        this.disabled = false;
        this.$store.dispatch("setLoader", false);
        this.$router.push("/");
      }
    },
  },
};
</script>

<style scoped>
.container {
  height: 100%;
}

.col-md-12 {
  padding: 5px;
}

.home__banner {
  position: absolute;
  bottom: 0;
  right: 0;
}

.explore__btn {
  position: absolute;
  top: 50px;
  right: 50px;
}

@media only screen and (max-width: 600px) {
  .explore__btn,
  .home__banner {
    display: none !important;
  }

  .v-form {
    padding: 10px;
  }

  .forgot {
    float: none !important;
    display: block;
    margin-top: 5px;
  }

  .v-btn {
    display: block;
    margin: 0 auto;
  }

  >>> .v-label {
    display: block !important;
  }

  h4 {
    float: none;
    margin-top: 5px;
  }
}
</style>
<template>
  <v-skeleton-loader
    type="card, list-item-three-line, actions"
  ></v-skeleton-loader>
</template>

<script>
export default {
  name: "CardSkeleton",
};
</script>

<style scoped>
</style>
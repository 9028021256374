import { auth, usersRef } from "../config/firebase"
import store from '../store'

export const authGuard = () => {
    return new Promise((resolve) => {
        auth.onAuthStateChanged(async user => {
            if (user) {
                const docRef = await usersRef.doc(user.uid).get()
                store.dispatch('setCurrentUser', docRef.data())
                resolve(true)
            }
            else resolve(false)
        })
    })
}